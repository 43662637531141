<div class="modal-card card">
    <div class="card-header">
        <h4 class="card-header-title" id="exampleModalCenterTitle">
            Histórico de Precificação
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="modal-body">
    <div class="table-responsive">
        <table class="table table-sm table-hover table-nowrap card-table">
            <thead>
                <tr>
                    <th>Data</th>
                    <th class="text-center">Tabela</th>
                    <th class="text-right">Preço</th>
                </tr>
            </thead>
            <tbody class="list">
                <tr *ngFor="let item of historico">
                    <td class="">{{item.dataConsulta|date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">{{item.tabelaReferencia}}</td>
                    <td class="text-right">{{item.preco| currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>