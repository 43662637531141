import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RegrasCriarComponent } from '../regras-criar/regras-criar.component';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import {
  ApiClient,
  ListarRegraGarantiaResponseItem,
  IncluirRegraGarantiaRequest,
  OrdemSelecaoGarantiaAutomatica
} from 'src/app/core/http/api-client.generated';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-reglas-listar',
  templateUrl: './regras-listar.component.html',
  styleUrls: ['./regras-listar.component.css']
})
export class RegrasListarComponent implements OnInit {

  bsModalRef: BsModalRef;

  public reglas!: ListarRegraGarantiaResponseItem[];
  public incluirRegraGarantiaRequest: IncluirRegraGarantiaRequest = new IncluirRegraGarantiaRequest();

  constructor(private modalService: BsModalService,
              private apiClient: ApiClient,
              private cookieService: CookieService,
              private router: Router,
              private toastr: ToastrService,
              private authService: AuthService) {

    }

  ngOnInit(): void {
    this.ListarRegras();
  }

  AdicionarRegra() {
    this.bsModalRef = this.modalService.show(RegrasCriarComponent);

    this.bsModalRef.content.event.subscribe((res) => {

      this.incluirRegraGarantiaRequest.instituicaoFinanceiraId = Number(this.authService.estabelecimentoId);
      this.incluirRegraGarantiaRequest.lojaId = res.lojaId;
      this.incluirRegraGarantiaRequest.contratoId = res.contratoId;
      this.incluirRegraGarantiaRequest.tabelaReferencia = res.tabelaReferencia;
      this.incluirRegraGarantiaRequest.ordemSelecaoGarantiaAno = Number(res.ordemSelecaoGarantiaAno);
      this.incluirRegraGarantiaRequest.ordemSelecaoGarantiaPreco = Number(res.ordemSelecaoGarantiaPreco);
      this.incluirRegraGarantiaRequest.filtros = res.filtros;

      this.cookieService.putObject('regra', this.incluirRegraGarantiaRequest);

      this.router.navigate(['/configuracoes/regras-garantia/itens/criar']);

      /*this.apiClient.regrasGarantia4(this.incluirRegraGarantiaRequest).subscribe( (resp) => {

        this.reglas.push(res);

      });*/
    });
  }

  ExcluirRegra(item, indice) {
    let msg = 'Excluir a regra ';
    msg += item.marca !== '' ? item.marca + ' > ' : '';
    msg += item.modelo !== '' ? item.modelo + ' > ' : '';
    msg += item.idade !== '' ? item.idade + ' > ' : '';
    msg += item.km !== '' ? item.km + ' > ' : '';
    msg += item.uf !== '' ? item.uf + ' > ' : '';
    msg += item.chassi !== '' ? item.chassi + ' > ' : '';
    msg += item.leilao !== '' ? item.leilao : '';
    msg += '?';

    this.bsModalRef = this.modalService.show(ModalConfirmComponent, { initialState: {
      titulo: 'Excluir regra',
      mensaje: msg.replace(' > ?', '?'),
      textCancelar: 'NÃO',
      textConfirmar: 'SIM'
    }, class: 'modal-sm'});

    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        this.reglas.splice(indice, 1);
      }
    });
  }

  ListarRegras() {
    this.apiClient.regrasGarantia3(Number(this.authService.estabelecimentoId)).subscribe( (resp) => {
      this.reglas = resp.regrasGarantia;
    });
  }

  goDetalheItem(item){

    this.cookieService.putObject('regra', item);

    this.router.navigateByUrl('/configuracoes/regras-garantia/itens/detalhe/' + item.id);
  }

  getItemOrdemSelecao(item) {
    let result = '';
    switch (item) {
      case OrdemSelecaoGarantiaAutomatica._0:
        result = 'Aleatório';
        break;
      case OrdemSelecaoGarantiaAutomatica._1:
        result = 'Ano Crescente';
        break;
      case OrdemSelecaoGarantiaAutomatica._2:
        result = 'Ano Decrescente';
        break;
      case OrdemSelecaoGarantiaAutomatica._3:
        result = 'PrecoCrescente';
        break;
      case OrdemSelecaoGarantiaAutomatica._4:
        result = 'PrecoDecrescente';
        break;
      default:
        result = '';
        break;
    }

    return result;
  }

}
