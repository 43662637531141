import { ListarEstoqueResponseItem } from "../core/http/api-client.generated";

export class EstoqueGarantiaItem extends ListarEstoqueResponseItem {

    precoInstituicaoFinanceira?: number | undefined;

    constructor(data?: ListarEstoqueResponseItem, precoInstituicaoFinanceira?: number | undefined) {
        super(data);
        this.precoInstituicaoFinanceira = precoInstituicaoFinanceira;
    }

    init(_data?: any) {
        super.init(_data)
        if (_data) {
            this.precoInstituicaoFinanceira = _data["precoInstituicaoFinanceira"];
        }
    }

    static fromJS(data: any): EstoqueGarantiaItem {
        data = typeof data === 'object' ? data : {};
        let result = new EstoqueGarantiaItem();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        super.toJSON(data);
        data["precoInstituicaoFinanceira"] = this.precoInstituicaoFinanceira;
        return data;
    }
}