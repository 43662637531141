import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import * as moment from 'moment';
import { ApiClient, IListarInstituicaoFinanceiraResponseItem, IListarLojaResponseItem } from 'src/app/core/http/api-client.generated';
import { ToastrService } from 'ngx-toastr';

defineLocale('ptbr', ptBrLocale);
@Component({
  selector: 'app-whitelist-criar',
  templateUrl: './whitelist-criar.component.html',
  styleUrls: ['./whitelist-criar.component.css']
})
export class WhitelistCriarComponent implements OnInit {

  formGroup!: FormGroup;
  titulo: string;
  data: any;
  bsValue = new Date();
  lojas!: IListarLojaResponseItem[];
  institucaoFinanceira!: IListarInstituicaoFinanceiraResponseItem[];
  tipo = 'criar';
  submitted = false;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private localeService: BsLocaleService,
    private apiClient: ApiClient,
    private toastr: ToastrService) {
      this.localeService.use('ptbr');
    }

  get f() { return this.formGroup.controls; }


  ngOnInit(): void {

    this.formGroup = this.formBuilder.group({
      lojaId: [null, Validators.compose([Validators.required])],
      instituicaoFinanceiraId: [null, Validators.compose([Validators.required])],
      prazo: [null, Validators.compose([Validators.required])]
    });

    if (this.tipo === 'alterar') {
      this.formGroup.controls.lojaId.setValue(this.data.lojaId);
      this.formGroup.controls.lojaId.disable();
      this.formGroup.controls.instituicaoFinanceiraId.setValue(this.data.instituicaoFinanceiraId);
      this.formGroup.controls.instituicaoFinanceiraId.disable();
      this.formGroup.controls.prazo.setValue(moment(this.data.prazo).toDate() );
    }

    this.getLojas();
    this.getInstitucaoFinanceira();
  }

  Agregar() {

    this.submitted = true;
    if (!this.formGroup.valid) {
      return;
    }

    this.formGroup.value.prazo = moment(this.formGroup.value.prazo).format('YYYY-MM-DDThh:mm:ss[Z]');
    // tslint:disable-next-line: radix
    this.formGroup.value.lojaId = parseInt(this.formGroup.value.lojaId);
    // tslint:disable-next-line: radix
    this.formGroup.value.instituicaoFinanceiraId = parseInt(this.formGroup.value.instituicaoFinanceiraId);

    this.event.emit(this.formGroup.value);
    this.bsModalRef.hide();
  }

  getLojas() {
    this.apiClient.lojas().subscribe( (resp) => {
      this.lojas = resp.lojas;
    });
  }

  getInstitucaoFinanceira() {
    this.apiClient.instituicoesFinanceiras().subscribe( (resp) => {
      this.institucaoFinanceira = resp.instituicoesFinanceiras;
    });
  }
}
