import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../core/auth/auth.service';
import { ApiClient, AutorizacaoRequest } from 'src/app/core/http/api-client.generated';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public formGroup!: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private apiClient: ApiClient,
    private toastr: ToastrService) { }

  get f() { return this.formGroup.controls; }

  ngOnInit(): void {

    this.formGroup = this.formBuilder.group({
      cpf: [null, [Validators.required]],
      senha: [null, [Validators.required]]
    });
  }

  async onSubmit() {
    this.submitted = true;
    
    if (!this.formGroup.valid) {
      return;
    }

    this.apiClient.login(this.formGroup.value).subscribe(data => {
      this.authService.token = data.token;

      let autorizacaoRequest: AutorizacaoRequest = new AutorizacaoRequest();
      autorizacaoRequest.documento = this.formGroup.controls.cpf.value;
      this.apiClient.regras(autorizacaoRequest).subscribe((resp) => {
        this.authService.estabelecimentoId = Number(resp.idEstabelecimento);
        this.authService.permissoes = resp.listaAutorizacao.map(p => p.codigoPermissao);
        this.router.navigate(['/home']);
      });
    });
  }
}
