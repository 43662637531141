import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm-comentario',
  templateUrl: './modal-confirm-comentario.component.html',
  styleUrls: ['./modal-confirm-comentario.component.css']
})
export class ModalConfirmComentarioComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    ) { }

  formGroup!: FormGroup;
  public titulo: string;
  public mensaje: string;
  submitted = false;
  public event: EventEmitter<any> = new EventEmitter();

  get f() { return this.formGroup.controls; }

  ngOnInit(): void {
    const nonWhitespaceRegExp: RegExp = new RegExp('\\S');
    this.formGroup = this.formBuilder.group({
      motivo: [null, [Validators.required, Validators.pattern(nonWhitespaceRegExp)]],
    });


  }

  confirmar() {
    this.submitted = true;

    if (!this.formGroup.valid) {
      return;
    }

    this.event.emit(this.formGroup.value.motivo.trim());
    this.bsModalRef.hide();
  }

}
