<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Extrato Financeiro
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col">
                                    <form [formGroup]="formulario">
                                        <div class="input-group input-group-flush">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fe fe-calendar"></i></span>
                                            </div>
                                            <select class="form-control" formControlName="instituicaoFinanceiraId" *ngIf="authService.estabelecimentoId == 0">
                                                <option [value]="0" disabled>Selecione a Instituição Financeira</option>
                                                <option *ngFor="let instituicao of instituicoesFinanceiras" [value]="instituicao.id">{{ instituicao.razaoSocial }}</option>
                                            </select>
                                            <input type="text" name="daterangepickerModel" placeholder="Selecione o período" class="form-control" bsDaterangepicker [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-default',dateInputFormat: 'DD/MM/YYYY' }" [bsConfig]="{ adaptivePosition: true }" formControlName="data">
                                            <button class="btn btn-outline-info" (click)="getExtrato()">
                                                <span class="fe fe-search"></span>
                                                Buscar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>Descrição</th>
                                        <th class="text-center">Quantidade</th>
                                        <th class="text-right">Valor Unitário</th>
                                        <th class="text-right">Valor Total</th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr *ngFor="let item of extrato">
                                        <td class="">{{item.descricao}}</td>
                                        <td class="text-center">{{item.quantidade}}</td>
                                        <td class="text-right">{{item.valorUnitario | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                        <td class="text-right">{{item.valorTotal | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                    </tr>
                                </tbody>
                                <tfoot style="color: #95aac9; text-transform: uppercase; font-weight: 600; font-size: .625rem; letter-spacing: .08em;">
                                    <tr>
                                        <td style="padding-left: 1.5rem">Total</td>
                                        <td class="text-center">{{ totalQuantidade }}</td>
                                        <td></td>
                                        <td style="padding-right: 1.5rem" class="text-right">{{ totalValor | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>