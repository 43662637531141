import { Component, OnInit } from '@angular/core';
import { ApiClient, IListarInstituicaoFinanceiraResponseItem} from 'src/app/core/http/api-client.generated';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})
export class ParametrosComponent implements OnInit {

  public instituicao!: IListarInstituicaoFinanceiraResponseItem[];

  constructor(
    private apiClient: ApiClient,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.getInstitucaoFinanceira();
  }

  getInstitucaoFinanceira() {
    this.apiClient.instituicoesFinanceiras().subscribe((resp) => {
      this.instituicao = resp.instituicoesFinanceiras;
    });
  }

}
