import { Component, OnInit } from '@angular/core';
import {
  ApiClient,
  FiltroContratoStatus,
  ListarContratosResponseItem,
  ListarInstituicaoFinanceiraResponseItem
  } from 'src/app/core/http/api-client.generated';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-relatorio-situacao',
  templateUrl: './relatorio-situacao.component.html',
  styleUrls: ['./relatorio-situacao.component.css']
})
export class RelatorioSituacaoComponent implements OnInit {
  contratos: ListarContratosResponseItem[];
  formulario: FormGroup;
  instituicoesFinanceiras: ListarInstituicaoFinanceiraResponseItem[] = [];

  constructor(private apiClient: ApiClient,
              private router: Router,
              public authService: AuthService,
              private toastr :ToastrService) {
    this.formulario = new FormGroup({
      instituicaoFinanceiraId: new FormControl(0, Validators.required),
      filtro: new FormControl(null),
      status: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    if (this.authService.estabelecimentoId > 0) {
      this.formulario.get('instituicaoFinanceiraId').setValue(this.authService.estabelecimentoId);
    } else {
      this.apiClient.instituicoesFinanceiras().subscribe(response => this.instituicoesFinanceiras = response.instituicoesFinanceiras);
    }
  }

  buscar() {
    if (this.formulario.get('instituicaoFinanceiraId').value == 0) {
      this.toastr.error('Selecione a instituição financeira');
      return;
    }
  
    if (this.formulario.get('filtro').value == null) {
      this.formulario.get('filtro').setValue(undefined);
    }

    const status = this.formulario.get('status').value || undefined;

    this.apiClient.contratos4(this.formulario.get('instituicaoFinanceiraId').value, undefined, this.formulario.get('filtro').value, status).subscribe(response => {
        this.contratos = response.contratos;
        if (response.contratos?.length == 0) {
          this.toastr.info('A consulta não retornou nenhum registro.');
        }
    });
  }

  getDetalhe(id) {
    this.router.navigate(['/relatorios/detalhe/' + id]);
  }

}
