import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContratosListarComponent } from './contratos-listar/contratos-listar.component';
import { ContratosCriarComponent } from './contratos-criar/contratos-criar.component';
import { ContratosRoutingModule } from './contratos-routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxCurrencyModule } from 'ngx-currency';
import { ToastrModule } from 'ngx-toastr';
import { ContratosDetalhesComponent } from './contratos-detalhes/contratos-detalhes.component';

@NgModule({
  declarations: [
    ContratosCriarComponent,
    ContratosListarComponent,
    ContratosDetalhesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ContratosRoutingModule,
    TabsModule.forRoot(),
    NgxCurrencyModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ]
})

export class ContratosModule{

}
