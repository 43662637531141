import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './core/auth/auth.service';
import { user } from './shared/models/user.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  token!: user;
  title = 'datastock-geerostock';

  constructor(
    private authService: AuthService,
    private router: Router) {
      this.authService.currentUser.subscribe(x => this.token = x);
    }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(x => this.token = x)
  }

  get autenticado(): boolean {
    return this.authService.permissoes?.length > 0;
  }

}
