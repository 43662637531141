<div class="modal-card card">
    <div class="card-header">
        <h4 class="card-header-title" id="exampleModalCenterTitle">{{titulo}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="modal-body">
    <p class="mb-0">{{mensaje}}</p>
</div>
<div class="modal-footer ">
    <div class="text-center col">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal" (click)="confirmar()">{{textConfirmar}}</button>&nbsp;
        <button type="button" *ngIf="showCancel || showCancel == undefined" class="btn btn-outline-dark" data-dismiss="modal" (click)="bsModalRef.hide()">{{textCancelar}}</button> </div>
</div>