import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';
import { ModalConfirmComentarioComponent } from 'src/app/shared/layout/modal-confirm-comentario/modal-confirm-comentario.component';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import {
  ApiClient,
  IListarLojaResponseItem,
  IncluirGarantiasContratoVeiculoRequest,
  ListarEstoqueResponseItem,
  ListarGarantiasContratoResponseItem,
  ListarRegraGarantiaResponseItem
} from 'src/app/core/http/api-client.generated';
import { AuthService } from 'src/app/core/auth/auth.service';
import { EstoqueGarantiaItem } from 'src/app/model/EstoqueGarantiaItem';

@Component({
  selector: 'app-contratos-detalhes',
  templateUrl: './contratos-detalhes.component.html',
  styleUrls: ['./contratos-detalhes.component.css']
})
export class ContratosDetalhesComponent implements OnInit {

  idContrato: string;
  minDate: Date;
  formGroup!: FormGroup;
  bsModalRef: BsModalRef;
  bsModalRef2: BsModalRef;
  garantias: ListarGarantiasContratoResponseItem[];
  garantiasDeEstoque: EstoqueGarantiaItem[] = [];
  lojas!: IListarLojaResponseItem[];
  veiculosGarantia: IncluirGarantiasContratoVeiculoRequest[] = [];
  currencyOption = environment.currencyOption;
  tipo = 'criar';
  propostaCancelar = false;
  enviarLoja = false;
  aprovarProposta = false;
  recusarProposta = false;
  submitted = false;
  qtdGarantia = 0;
  permiteAlteracaoGarantia = false;
  permiteAtualizarContrato = false;
  regras: ListarRegraGarantiaResponseItem[] = [];

  constructor(private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private apiClient: ApiClient,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService) {
  }

  get f() { return this.formGroup.controls; }

  ngOnInit(): void {
    this.minDate = new Date();
    this.formGroup = this.formBuilder.group({
      lojaId: [null, Validators.compose([Validators.required])],
      regraGarantiaId: [null],
      numero: [null, Validators.compose([Validators.required])],
      valorCredito: [0, Validators.compose([Validators.required, Validators.min(0.01)])],
      valorGarantia: [0, Validators.compose([Validators.required, Validators.min(0.01)])],
      dataTermino: [null, Validators.compose([Validators.required])],
      tipo: ['1', Validators.compose([Validators.required])],
      tipoSelecaoGarantia: ['1', Validators.compose([Validators.required])],
      status: [null],
      loja: [null],
      idGeero: [null],
      totalSelecionado: [0]
    });
    // tslint:disable-next-line: deprecation
    this.route.params.subscribe(params => {
      this.idContrato = params.id;
    });

    this.getContrato();
    this.getLojas(this.authService.estabelecimentoId);
  }

  getContrato() {
    this.tipo = '';
    // tslint:disable-next-line: deprecation
    this.apiClient.contratos(Number(this.idContrato)).subscribe((resp) => {
      this.formGroup.controls.lojaId.setValue(resp.lojaId);
      this.formGroup.controls.lojaId.disable();
      this.formGroup.controls.idGeero.setValue(resp.idGeero);
      this.formGroup.controls.loja.setValue(resp.loja);
      this.formGroup.controls.loja.disable();
      this.formGroup.controls.numero.setValue(resp.numero);
      this.formGroup.controls.numero.disable();
      this.formGroup.controls.valorCredito.setValue(resp.valorCredito);
      this.formGroup.controls.valorGarantia.setValue(resp.valorGarantia);
      this.formGroup.controls.dataTermino.setValue(moment(resp.dataTermino).toDate());
      this.formGroup.controls.tipo.setValue(this.getTipoContratoEnum(resp.tipo));
      this.formGroup.controls.tipo.disable();
      this.formGroup.controls.tipoSelecaoGarantia.setValue(resp.tipo.toString());
      this.formGroup.controls.tipoSelecaoGarantia.disable();
      this.formGroup.controls.status.setValue(resp.status);
      this.formGroup.controls.status.disable();

      this.apiClient.regrasGarantia3(this.authService.estabelecimentoId).subscribe(response => {
        this.regras = response.regrasGarantia.filter(r => r.aplicacao == 'Geral' || (r.aplicacao == 'Loja' && r.lojaId == this.formGroup.controls.lojaId.value));
        this.formGroup.controls.regraGarantiaId.setValue(resp.regraGarantiaId);
        this.getGarantias(this.idContrato);
      });

      this.permiteAlteracaoGarantia = false;


      if (resp.status === 'PendenteAprovacaoGarantiaInstituicaoFinanceira') {
        this.recusarProposta = true;
        this.aprovarProposta = true;
      }

      if (resp.status !== 'Cancelado' && resp.status !== 'Liquidado') {
        this.permiteAtualizarContrato = true;
      }

      if (resp.status === 'Rascunho' || resp.status === 'PendenteSelecaoGarantiaInstituicaoFinanceira') {
        this.enviarLoja = true;
        this.permiteAlteracaoGarantia = true;
      }

      if (resp.status === 'PendenteSelecaoGarantiaLoja' ||
        resp.status === 'PendenteAprovacaoGarantiaLoja' ||
        resp.status === 'Rascunho' ||
        resp.status === 'PendenteAprovacaoGarantiaInstituicaoFinanceira' ||
        resp.status === 'PendenteSelecaoGarantiaInstituicaoFinanceira') {
        this.propostaCancelar = true;
      }
    });
  }

  getTipoContratoEnum(idContrato) {
    let contrato;
    switch (idContrato) {
      case 'Parcelado':
        contrato = '0';
        break;
      case 'Rotativo':
        contrato = '1';
        break;
      default:
        contrato = '';
        break;
    }
    return contrato;
  }

  getLojas(id) {
    // tslint:disable-next-line: deprecation
    this.apiClient.whitelist(Number(id)).subscribe((resp) => {
      this.lojas = resp.lojas;
    });
  }

  getGarantias(id) {
    this.garantiasDeEstoque = [];
    // tslint:disable-next-line: deprecation
    this.apiClient.garantias(Number(id)).subscribe((resp) => {
      this.garantias = resp.garantias;
      this.qtdGarantia = this.garantias.length;
      this.garantias.forEach(g => {
        const garantia = new EstoqueGarantiaItem(g, g.precoInstituicaoFinanceira ?? g.precoVeiculo);
        garantia.emGarantia = true;
        this.garantiasDeEstoque.push(garantia);
      });      
      this.apiClient.estoque(this.formGroup.controls.lojaId.value, undefined, undefined, this.formGroup.controls.regraGarantiaId.value).subscribe((resp) => {
        this.garantiasDeEstoque = this.garantiasDeEstoque.concat(resp.estoque.map(e => new EstoqueGarantiaItem(e, e.preco)));  
        this.SelecionarGarantias();
      });
    });
  }

  salvarRascunho() {
    this.submitted = true;
    if (!this.formGroup.valid) {
      return;
    }

    if (this.garantiasDeEstoque.filter(g => g.emGarantia == true && g.precoInstituicaoFinanceira <= 0).length > 0) {
      return;
    }

    this.formGroup.value.instituicaoFinanceiraId = this.authService.estabelecimentoId;
    this.formGroup.value.tipo = Number(this.formGroup.value.tipo);
    this.formGroup.value.lojaId = Number(this.formGroup.value.lojaId);
    this.formGroup.value.tipoSelecaoGarantia = Number(this.formGroup.value.tipoSelecaoGarantia);
    this.formGroup.value.regraGarantiaId = Number(this.formGroup.value.regraGarantiaId);
    this.formGroup.value.dataTermino = moment(this.formGroup.value.dataTermino).format('YYYY-MM-DDThh:mm:ss[Z]');
    // tslint:disable-next-line: deprecation
    this.apiClient.contratos3(this.formGroup.value).subscribe((resp) => {
      /*this.tipo = 'rascunho';
      this.router.navigateByUrl('/contratos/criar/' + resp.id);*/
    });

  }

  get totalEmGarantia(): number {
    return this.veiculosGarantia?.map(v => v.precoInstituicaoFinanceira || v.precoVeiculo).reduce((valor, soma) => valor + soma, 0);
  }
  
  SelecionarGarantias() {
    this.qtdGarantia = 0;
    this.veiculosGarantia = [];

    this.garantiasDeEstoque.forEach(x => {
      if (x.emGarantia) {
        var garantia = new IncluirGarantiasContratoVeiculoRequest();

        garantia.veiculoId = x.id;
        garantia.precoVeiculo = x.preco;
        garantia.precoInstituicaoFinanceira = x.precoInstituicaoFinanceira;

        this.veiculosGarantia.push(garantia);
      }
    });

    this.qtdGarantia = this.veiculosGarantia.length;
    this.formGroup.controls.totalSelecionado.setValue(this.totalEmGarantia);
  }

  CancelSelect() {
    this.veiculosGarantia = [];
    this.garantiasDeEstoque.find(x => {
      if (x.emGarantia) {
        x.emGarantia = false;
      }
    });
  }

  AdicionarGarantias(idGarantia) {
    // tslint:disable-next-line: deprecation
    this.apiClient.garantias2(idGarantia, undefined, this.veiculosGarantia).subscribe((resp) => {
      this.toastr.success('Garantias atualizadas com sucesso.');
    });
  }

  AtualizarRascunho() {
    this.formGroup.value.id = Number(this.idContrato);
    this.formGroup.value.status = this.getContratoStatusEnum(this.formGroup.controls.status.value);
    // tslint:disable-next-line: deprecation
    this.formGroup.value.regraGarantiaId = Number(this.formGroup.value.regraGarantiaId);
    this.apiClient.contratos2(Number(this.idContrato), this.formGroup.value).subscribe((resp) => {
      if (this.permiteAlteracaoGarantia) {
        if (this.veiculosGarantia.length > 0) {
          this.AdicionarGarantias(resp.id);
        }
      }
      this.toastr.success('Contrato atualizado com sucesso.');
    });
  }

  liquidar() {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: {
        titulo: 'Liquidar contrato',
        mensaje: 'Deseja liquidar este contrato?',
        textCancelar: 'NÃO',
        textConfirmar: 'SIM'
      }
    });

    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        // tslint:disable-next-line: deprecation
        this.formGroup.value.id = Number(this.idContrato);
        this.formGroup.value.status = this.getContratoStatusEnum('Liquidado');
        // tslint:disable-next-line: deprecation
        this.apiClient.contratos2(Number(this.idContrato), this.formGroup.value).subscribe((resp) => {
          this.toastr.success('Contrato liquidado com sucesso');
          this.router.navigateByUrl('/contrato');
        });
      }
    });

  }

  enviarLojista() {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: {
        titulo: 'Enviar para loja',
        mensaje: 'Enviar esta proposta para loja?',
        textCancelar: 'NÃO',
        textConfirmar: 'SIM'
      }
    });

    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        // tslint:disable-next-line: deprecation
        this.apiClient.enviar(Number(this.idContrato)).subscribe(response => {
          if (response.valorGarantia > this.formGroup.controls.valorGarantia.value) {
            this.toastr.info('O valor da garantia foi alterado devido ao valor dos veículos selecionados.');
          }
          this.toastr.success('Proposta enviada para a loja.');
          this.router.navigateByUrl('contrato/listar');
        });
        this.enviarLoja = true;
      }
    });
  }

  aprovarPropos() {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: {
        titulo: 'Aprovar proposta',
        mensaje: 'Deseja realmente aprovar esta proposta?',
        textCancelar: 'NÃO',
        textConfirmar: 'SIM'
      }
    });

    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        this.apiClient.aprovar(Number(this.idContrato)).subscribe((resp) => {
          this.toastr.success('Proposta aprovada. O contrato encontra-se ativo.');
          this.router.navigateByUrl('contrato/listar');
        });
      }
    });
  }

  recusarPropos() {
    this.bsModalRef2 = this.modalService.show(ModalConfirmComentarioComponent, {
      initialState: {
        titulo: 'Motivo',
        mensaje: 'Informe o motivo da recusa:'
      }
    });

    this.bsModalRef2.content.event.subscribe(motivo => {
      if (motivo !== '' || motivo !== null || motivo !== undefined) {

        // tslint:disable-next-line: deprecation
        this.apiClient.recusar(Number(this.idContrato), motivo).subscribe((resp) => {
          this.toastr.success('Proposta recusada.');
          this.router.navigateByUrl('contrato/listar');
        });
        this.recusarProposta = true;
      }
    });
  }

  cancelarProposta() {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: {
        titulo: 'Cancelar Proposta',
        mensaje: 'Deseja realmente cancelar esta proposta?',
        textCancelar: 'NÃO',
        textConfirmar: 'SIM'
      }
    });

    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        // tslint:disable-next-line: deprecation
        this.apiClient.cancelar(Number(this.idContrato)).subscribe((resp) => {
          this.toastr.success('Proposta cancelada.');
          this.router.navigateByUrl('contrato/listar');
        });
        this.propostaCancelar = true;
      }
    });
  }

  getContratoStatusEnum(status) {
    let idStatus;
    switch (status) {
      case 'Rascunho':
        idStatus = 0;
        break;
      case 'PendenteSelecaoGarantiaLoja':
        idStatus = 1;
        break;
      case 'PendenteSelecaoGarantiaInstituicaoFinanceira':
        idStatus = 2;
        break;
      case 'PendenteAprovacaoGarantiaLoja':
        idStatus = 3;
        break;
      case 'PendenteAprovacaoGarantiaInstituicaoFinanceira':
        idStatus = 4;
        break;
      case 'Ativo':
        idStatus = 5;
        break;
      case 'Cancelado':
        idStatus = 6;
        break;
      case 'Liquidado':
        idStatus = 7;
        break;
      default:
        idStatus = '';
        break;
    }
    return idStatus;
  }
}
