import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
  ApiClient,
  ListarVeiculoPrecificacaoResponseItem
} from 'src/app/core/http/api-client.generated';

@Component({
  selector: 'app-relatorio-historico-detalhe',
  templateUrl: './relatorio-historico-detalhe.component.html',
  styleUrls: ['./relatorio-historico-detalhe.component.css']
})
export class RelatorioHistoricoDetalheComponent implements OnInit {

  id: string;
  public historico: ListarVeiculoPrecificacaoResponseItem[];

  constructor(
    private apiClient: ApiClient,
    public bsModalRef: BsModalRef
    ) { }

  ngOnInit(): void {
    // tslint:disable-next-line: radix
    this.apiClient.historicoPrecificacao(parseInt(this.id)).subscribe( (resp) => {
      this.historico = resp.precificacoes;
    });
  }

}
