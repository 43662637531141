<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Whitelist
                    </h1>
                </div>
                <div class="col-auto">
                    <a (click)="NovoWhitelist()" data-toggle="modal" class="btn btn-primary lift">
                        <span class="fe fe-plus"></span>
                        <span class="d-none d-md-inline">Novo</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>Loja</th>
                                        <th>Instituição Financeira</th>
                                        <th colspan="2">Prazo</th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr *ngFor="let item of whitelist; let i = index" class="cursor-pointer">
                                        <td class="">{{item.loja}}</td>
                                        <td class="">{{item.instituicaoFinanceira}}</td>
                                        <td class="">{{item.prazo | date:'dd/MM/yyyy'}}</td>
                                        <td class="text-right">
                                            <div dropdown container="table">
                                                <a class="dropdown-ellipses dropdown-toggle" dropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fe fe-more-vertical"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                                                    <a (click)="AlterarWhitelist(item)" class="dropdown-item" data-toggle="modal">Alterar prazo</a>
                                                    <a (click)="ExcluirWhitelist(item.id)" class="dropdown-item" data-toggle="modal">Excluir registro</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
