import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';

import {
  AlterarItensRegraGarantiaRequestItem,
  ApiClient,
  IncluirItensRegraGarantiaRequest,
  AlterarItensRegraGarantiaRequest,
  IncluirItensRegraGarantiaRequestItem,
  ListarItensRegraGarantiaResponseItem
  } from 'src/app/core/http/api-client.generated';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';
import { RegraItemsCriarComponent } from '../regra-items-criar/regra-items-criar.component';

@Component({
  selector: 'app-regra-detalhes',
  templateUrl: './regra-detalhes.component.html',
  styleUrls: ['./regra-detalhes.component.css']
})
export class RegraDetalhesComponent implements OnInit {
  itens: any[] = [];
  alterarItensRegraGarantiaRequestItem: AlterarItensRegraGarantiaRequestItem = new AlterarItensRegraGarantiaRequestItem();
  itensRequest: IncluirItensRegraGarantiaRequestItem[] = [];
  itensEditar: AlterarItensRegraGarantiaRequestItem[] = [];
  alterarItensRegraGarantiaRequest: AlterarItensRegraGarantiaRequest =
  new AlterarItensRegraGarantiaRequest();
  incluirItensRegraGarantiaRequest: IncluirItensRegraGarantiaRequest =
  new IncluirItensRegraGarantiaRequest();
  regraHead: any;
  idIten: string;
  bsModalRef: BsModalRef;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiClient: ApiClient,
    private modalService: BsModalService,
    private cookieService: CookieService,
    private toastr: ToastrService
    ) {
      this.regraHead = this.cookieService.getObject('regra');
    }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idIten = params.id;
    });

    this.getItens();
  }

  getItens() {
    // tslint:disable-next-line: radix
    this.apiClient.itens(parseInt(this.idIten)).subscribe((resp) => {
      this.itens = resp.itens;
    });
  }

  AdicionarIten() {
    this.bsModalRef = this.modalService.show(RegraItemsCriarComponent, {
      initialState: {
        tipo: 'Novo',
        filtrosRegra: this.regraHead
      }
    });

    this.bsModalRef.content.event.subscribe((res) => {
      this.itensRequest = [];
      let iten: IncluirItensRegraGarantiaRequestItem = new IncluirItensRegraGarantiaRequestItem();
      iten.veiculoMarcaIds = res.veiculoMarcaIds;
      iten.veiculoModeloIds = res.veiculoModeloIds;
      iten.veiculoTipoIds = res.veiculoTipoIds;
      iten.veiculoTipoCombustivelIds = res.veiculoTipoCombustivelIds;
      iten.idadeMaxima = res.idadeMaxima == null ? null : Number(res.idadeMaxima);
      iten.quilometragemMaxima = res.quilometragemMaxima == null ? null : Number(res.quilometragemMaxima);
      iten.veiculoUFs = res.veiculoUFs;
      iten.chassiRemarcado = res.chassiRemarcado;
      iten.leilao = res.leilao;
      iten.percentualTabelaReferencia = res.percentualTabelaReferencia == null ? null : Number(res.percentualTabelaReferencia);
      this.itensRequest.push(iten);

      this.incluirItensRegraGarantiaRequest.regraGarantiaId = Number(this.idIten);
      this.incluirItensRegraGarantiaRequest.itens = this.itensRequest;

      this.apiClient.itens2(Number(this.idIten), this.incluirItensRegraGarantiaRequest).subscribe( (resp2) => {
        this.toastr.success('Adicionado com sucesso');
        /*this.cookieService.remove('regra');*/
        this.getItens();
      });
    });
  }

  ExcluirItem(item) {
    this.itens.splice(this.itens.indexOf(item), 1);
    this.alterarItensRegraGarantiaRequest.regraGarantiaId = Number(this.idIten);
    this.alterarItensRegraGarantiaRequest.itens = Object.assign(this.itens);

    this.apiClient.itens3(Number(this.idIten), this.alterarItensRegraGarantiaRequest).subscribe( (resp2) => {
      this.toastr.success('Apagado com sucesso');
      this.getItens();
    });
  }

  EditarItem(item) {
    this.bsModalRef = this.modalService.show(RegraItemsCriarComponent, {
      initialState: {
        tipo: 'Editar',
        data: item,
        filtrosRegra: this.regraHead
      }
    });

    this.bsModalRef.content.event.subscribe((res) => {
      item.chassiRemarcado = res.chassiRemarcado;
      item.idadeMaxima = res.idadeMaxima == null ? null : Number(res.idadeMaxima);
      item.leilao = res.leilao;
      item.percentualTabelaReferencia = res.percentualTabelaReferencia == null ? null : Number(res.percentualTabelaReferencia);
      item.quilometragemMaxima = res.quilometragemMaxima == null ? null : Number(res.quilometragemMaxima);
      item.veiculoMarcas = res.veiculoMarcaIds;
      item.veiculoModelos = res.veiculoModeloIds;
      item.veiculoTipos = res.veiculoTipoIds;
      item.veiculoTiposCombustivel = res.veiculoTipoCombustivelIds;
      item.veiculoUFs = res.veiculoUFs;
      this.itensEditar = [];

      this.itens.forEach(element => {
        let iten: AlterarItensRegraGarantiaRequestItem = new AlterarItensRegraGarantiaRequestItem();
        iten.chassiRemarcado = element.chassiRemarcado;
        iten.id = element.id;
        iten.idadeMaxima = element.idadeMaxima;
        iten.leilao = element.leilao;
        iten.percentualTabelaReferencia = element.percentualTabelaReferencia;
        iten.quilometragemMaxima = element.quilometragemMaxima;
        iten.veiculoMarcaIds = (element.veiculoMarcas.length > 0 && element.veiculoMarcas[0].nome === undefined) ? element.veiculoMarcas :element.veiculoMarcas.map(x => x.id);
        iten.veiculoModeloIds = (element.veiculoModelos.length > 0 && element.veiculoModelos[0].nome === undefined) ? element.veiculoModelos :element.veiculoModelos.map(x => x.id);
        iten.veiculoTipoCombustivelIds = (element.veiculoTiposCombustivel.length > 0 && element.veiculoTiposCombustivel[0].nome === undefined) ? element.veiculoTiposCombustivel : element.veiculoTiposCombustivel.map(x => x.id);
        iten.veiculoTipoIds = element.veiculoTipos.length > 0 && element.veiculoTipos[0].nome === undefined ? element.veiculoTipos : element.veiculoTipos.map(x => x.id);
        iten.veiculoUFs = element.veiculoUFs.length > 0 && element.veiculoUFs[0].nome === undefined ? element.veiculoUFs : element.veiculoUFs.map(x => x.id);

        this.itensEditar.push(iten);
      });
      /*this.itensEditar = Object.assign(this.itens);*/
      this.alterarItensRegraGarantiaRequest.regraGarantiaId = Number(this.idIten);
      this.alterarItensRegraGarantiaRequest.itens = this.itensEditar;

      this.apiClient.itens3(Number(this.idIten), this.alterarItensRegraGarantiaRequest).subscribe( (resp2) => {
        this.toastr.success('Alterado com sucesso');
        this.getItens();
      });
    });
  }

  ApagarIten(item) {
    const i = this.itens.indexOf(item);

    if (i !== -1) {
      this.itens.splice(i, 1);
      this.alterarItensRegraGarantiaRequest.regraGarantiaId = Number(this.idIten);
      this.alterarItensRegraGarantiaRequest.itens = Object.assign(this.itens);

      this.apiClient.itens3(Number(this.idIten), this.alterarItensRegraGarantiaRequest).subscribe( (resp2) => {
        this.toastr.success('Apagado com sucesso');
        this.getItens();
      });
    }

  }

  findValue(value) {
    var rtn = this.regraHead.filtros.find(item => item.filtro === value);

    return rtn;

  }

}
