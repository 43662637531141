import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.css']
})
export class NavRightComponent implements OnInit {

  isCollapsedVeiculo = true;
  isCollapsedUsuario = true;
  isCollapsedConfiguracoes = true;
  isCollapsedSuporte = true;
  isCollapsedTutoriais = true;
  isCollapsedManual = true;
  isCollapsedGeral = true;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    // remove user from local storage to log user out
    this.authService.logout();
  }

}
