<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Contratos
                    </h1>
                </div>
                <div class="col-auto">
                    <a data-toggle="modal" class="btn btn-primary lift" routerLink="/contratos/criar">
                        <span class="fe fe-plus"></span>
                        <span class="d-none d-md-inline">Adicionar contrato</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col">
                                    <form>
                                        <div class="input-group input-group-flush">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fe fe-search"></i></span>
                                            </div>
                                            <input class="list-search form-control" type="search" placeholder="Buscar contrato" #txtBusca (keydown.enter)="getContratos(txtBusca.value)">
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- / .row -->
                        </div>
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>N° Geero</th>
                                        <th>Tipo</th>
                                        <th>Loja</th>
                                        <th class="text-right">Crédito</th>
                                        <th class="text-right">Garantia</th>
                                        <th>Situação</th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr *ngFor="let item of contratos" [routerLink]="['/contratos/detalhe', item.id]" style="cursor: pointer;">
                                        <td>{{ item.numero }}</td>
                                        <td>{{ item.idGeero }}</td>
                                        <td>{{ item.tipo }}</td>
                                        <td>{{ item.loja }}</td>
                                        <td class="text-right">{{item.valorCredito | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td class="text-right">{{item.valorGarantia | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td>{{ item.status }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>