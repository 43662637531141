import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateChild, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class PermissaoGuard implements CanActivate, CanActivateChild {
  constructor(private readonly tostr: ToastrService,
              private readonly router: Router,
              private readonly authService: AuthService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (next.data.permissao) {
        if (this.authService.permissoes.length > 0) {
            if (this.authService.permissoes.some(p => p.startsWith(next.data.permissao))) {
                return true;
            }
        }
    } else {
        return true;
    }
    this.tostr.error('Você não tem permissão para visualizar essa página', 'Ops');
    this.authService.logout();
    return false;
  }
}
