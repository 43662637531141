import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent implements OnInit {

  public titulo: string;
  public mensaje: string;
  public textConfirmar: string;
  public textCancelar: string;
  public showCancel: undefined | boolean;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef
    ) { }

  ngOnInit(): void {
    if (this.textConfirmar === '' || this.textConfirmar === null || this.textConfirmar === undefined) {
      this.textConfirmar = 'Confirmar';
    }

    if (this.textCancelar === '' || this.textCancelar === null || this.textCancelar === undefined) {
      this.textCancelar = 'Cancelar';
    }
  }

  confirmar() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }

}
