import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { ApiClient,
  AutorizacaoRequest
  } from 'src/app/core/http/api-client.generated';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private apiClient: ApiClient,
    private authService: AuthService
    ) {
      //this.getRegras();
     }

  ngOnInit(): void {
    
  }

  getRegras() {
    let autorizacaoRequest: AutorizacaoRequest = new AutorizacaoRequest();
    this.apiClient.regras(autorizacaoRequest).subscribe((resp) => {
      this.authService.estabelecimentoId = Number(resp.idEstabelecimento);
      this.authService.permissoes = resp.listaAutorizacao.map(p => p.codigoPermissao);
    });
  }

}
