import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  ApiClient,
  IListarLojaResponseItem,
  ListarContratosResponseItem,
  ListarVeiculoMarcaResponse,
  ListarVeiculoMarcaResponseItem,
  ListarVeiculoModeloResponseItem,
  VeiculoTipoCombustivelItemResponse,
  VeiculoTipoItemResponse,
  VeiculoUFItemResponse
} from 'src/app/core/http/api-client.generated';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-regra-items-criar',
  templateUrl: './regra-items-criar.component.html',
  styleUrls: ['./regra-items-criar.component.css']
})
export class RegraItemsCriarComponent implements OnInit {

  formGroup!: FormGroup;
  submitted = false;
  marcas!: ListarVeiculoMarcaResponse;
  modelos: ListarVeiculoMarcaResponseItem[] = [];
  tipoVeiculo!: VeiculoTipoItemResponse[];
  tipoCombustive!: VeiculoTipoCombustivelItemResponse[];
  ufs!: VeiculoUFItemResponse[];
  lojas!: IListarLojaResponseItem[];
  contrato: ListarContratosResponseItem[];
  tipo: string;
  data: any;
  filtrosRegra: any;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private apiClient: ApiClient,
    private toastr: ToastrService
    ) { }

  get f() { return this.formGroup.controls; }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      veiculoMarcaIds: [null],
      veiculoModeloIds: [null],
      veiculoTipoIds: [null],
      veiculoTipoCombustivelIds: [null],
      idadeMaxima: [null],
      quilometragemMaxima: [null],
      veiculoUFs: [null],
      chassiRemarcado: [false],
      leilao: [false],
      percentualTabelaReferencia: [null, Validators.required]
    });

    if (this.tipo === 'Editar') {
      this.formGroup.controls.veiculoMarcaIds.setValue(this.data.veiculoMarcas.map(x => x.id));
      this.formGroup.controls.veiculoModeloIds.setValue(this.data.veiculoModelos.map(x => x.id));
      this.formGroup.controls.veiculoTipoIds.setValue(this.data.veiculoTipos.map(x => x.id));
      this.formGroup.controls.veiculoTipoCombustivelIds.setValue(this.data.veiculoTiposCombustivel.map(x => x.id));
      this.formGroup.controls.idadeMaxima.setValue(this.data.idadeMaxima);
      this.formGroup.controls.quilometragemMaxima.setValue(this.data.quilometragemMaxima);
      this.formGroup.controls.veiculoUFs.setValue(this.data.veiculoUFs.map(x => x.id));
      this.formGroup.controls.chassiRemarcado.setValue(this.data.chassiRemarcado);
      this.formGroup.controls.leilao.setValue(this.data.leilao);
      this.formGroup.controls.percentualTabelaReferencia.setValue(this.data.percentualTabelaReferencia);

      if(this.data.veiculoMarcas.length > 0) {
        this.getModelo(this.formGroup.value.veiculoMarcaIds);
      }
    }

    this.getMarcas();
    this.getUfs();
    this.getTipo();
    this.getTipoCombustive();
  }

  getMarcas() {
    this.apiClient.marcas(undefined, undefined, 20).subscribe((resp) => {
      this.marcas = resp.toJSON();
    });
  }

  getModelo(id: any[]) {
    let modelos: ListarVeiculoModeloResponseItem[] = [];
    this.modelos = [];
    id.forEach(element => {
      this.apiClient.modelos(element).subscribe((resp) => {
        resp.listaVeiculoModelo.forEach(element2 => {
          let modelo: ListarVeiculoModeloResponseItem = new ListarVeiculoModeloResponseItem();
          modelo.id = element2.id;
          modelo.nome = element2.nome;
          this.modelos.push(modelo);
        });
      });
    });
  }

  getUfs() {
    this.apiClient.ufs().subscribe( (resp) => {
      this.ufs = resp.uFs;
    });
  }

  getTipo() {
    this.apiClient.tiposVeiculo().subscribe((resp) => {
      this.tipoVeiculo = resp.tipos;
    });
  }

  getTipoCombustive() {
    this.apiClient.tiposCobustivel().subscribe((resp) => {
      this.tipoCombustive = resp.tiposCombustivel;
    });
  }

  Agregar() {
    this.submitted = true;
    if (!this.formGroup.valid) {
      return;
    }
    this.event.emit(this.formGroup.value);
    this.bsModalRef.hide();
  }

  findValue(value){
    var rtn = this.filtrosRegra.filtros.find(item => item.filtro === value);

    return rtn;

  }
}
