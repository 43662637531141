import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RelatorioHistoricoComponent } from './relatorio-historico/relatorio-historico.component';
import { RelatorioSituacaoComponent } from './relatorio-situacao/relatorio-situacao.component';
import { RelatorioSituacaoDetalheComponent } from './relatorio-situacao-detalhe/relatorio-situacao-detalhe.component';
import { ExtratoFinanceirosComponent } from './extrato-financeiros/extrato-financeiros.component';
import { PermissaoGuard } from 'src/app/core/guards/permissao.guard';

const routes: Routes = [
  {
    path: 'historico-precificacao',
    component: RelatorioHistoricoComponent,
    data: { permissao: 'Relatorios.HistoricoPrecificacao.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'situacao-contratos',
    component: RelatorioSituacaoComponent,
    data: { permissao: 'Relatorios.SituacaoContratos.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'detalhe/:id',
    component: RelatorioSituacaoDetalheComponent,
    data: { permissao: 'Relatorios.SituacaoContratos.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'extrato-financeiro',
    component: ExtratoFinanceirosComponent,
    data: { permissao: 'Relatorios.ExtratoFinanceiro.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: '**',
    redirectTo: 'listar',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RelatorioRoutingModule { }
