<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Contratos
                    </h1>
                </div>
                <div class="col-auto">
                    <a routerLink='/contratos' class="btn btn-dark lift">
                        <span class="fe fe-arrow-left"></span>
                        <span class="d-none d-md-inline">Voltar</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row mt-1 mb-2">
        <div class="col text-right mb-3">
            <a class="btn btn-primary lift mr-1 d-block d-md-inline mt-1" *ngIf="enviarLoja" (click)="enviarLojista()">
                <span class="fe fe-arrow-right"></span>
                Enviar para loja
            </a>                
            <a class="btn btn-primary lift mr-1 d-block d-md-inline mt-1" *ngIf="aprovarProposta" (click)="aprovarPropos()">
                Aprovar proposta
            </a>                
            <a class="btn btn-primary lift mr-1 d-block d-md-inline mt-1" *ngIf="recusarProposta" (click)="recusarPropos()">
                Recusar proposta
            </a>                
            <a class="btn btn-primary lift mr-1 d-block d-md-inline mt-1" (click)="AtualizarRascunho()" *ngIf="permiteAtualizarContrato">
                <span class="fe fe-save"></span>
                Atualizar contrato
            </a>                
            <a class="btn btn-primary lift mr-1 d-block d-md-inline mt-1" (click)="liquidar()" *ngIf="f.status.value == 'Ativo'">
                <span class="fe fe-check"></span>
                Liquidar contrato
            </a>
            <a class="btn btn-danger lift mr-1 d-block d-md-inline mt-1 mb-1" *ngIf="propostaCancelar" (click)="cancelarProposta()">
                <span class="fe fe-x"></span>
                Cancelar proposta
            </a>                
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col-12 col-lg-12 col-xl-12">
            <tabset class="tab-content">
                <tab heading="Dados">
                    <form [formGroup]="formGroup">
                        <div class="form-row mt-2">
                            <div class="form-group col-md-6">
                                <label>Loja</label>
                                <input type="text" formControlName="loja" class="form-control">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Número</label>
                                <input type="text" formControlName="numero" class="form-control">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Nº Geero</label>
                                <input type="text" formControlName="idGeero" class="form-control">
                            </div>
                            <div class="form-group col-md-2">
                                <label>Regra de Garantia</label>
                                <select formControlName="regraGarantiaId" class="form-control" (change)="getGarantias(idContrato)">
                                    <option *ngFor="let r of regras" [value]="r.id">{{ r.aplicacao }}</option>
                                    <option [value]="0">Nenhuma</option>
                                </select>
                                <div *ngIf="submitted && f.regraGarantiaId.errors" class="text-danger">
                                    <div *ngIf="f.regraGarantiaId.errors">Campo obrigatório</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label>Tipo do Contrato</label>
                                <input type="text" class="form-control" [value]="formGroup.controls.tipo.value == 0 ? 'Parcelado' : 'Rotativo'" readonly>                                
                            </div>
                            <div class="form-group col-md-3">
                                <label>Data de Término</label>
                                <input type="text" formControlName="dataTermino" class="form-control" bsDatepicker [minDate]="minDate" [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-default',dateInputFormat: 'DD/MM/YYYY' }" [bsConfig]="{ adaptivePosition: true }" [isDisabled]="!permiteAtualizarContrato">
                                <div *ngIf="submitted && f.dataTermino.errors" class="text-danger">
                                    <div *ngIf="f.dataTermino.errors">Campo obrigatório</div>
                                </div>
                            </div>
                            <div class="form-group col-md-2">
                                <label>Valor do Crédito</label>
                                <input type="text" currencyMask [options]="currencyOption" formControlName="valorCredito" class="form-control" [readonly]="!permiteAtualizarContrato">
                                <div *ngIf="submitted && f.valorCredito.errors" class="text-danger">
                                    <div *ngIf="f.valorCredito.errors">Campo obrigatório</div>
                                </div>
                            </div>
                            <div class="form-group col-md-2">
                                <label>Valor da Garantia</label>
                                <input type="text" currencyMask [options]="currencyOption" formControlName="valorGarantia" class="form-control" [readonly]="!permiteAtualizarContrato">
                                <div *ngIf="submitted && f.valorGarantia.errors" class="text-danger">
                                    <div *ngIf="f.valorGarantia.errors">Campo obrigatório</div>
                                </div>
                            </div>
                            <div class="form-group col-md-2">
                                <label>Total Selecionado</label>
                                <input type="text" currencyMask [options]="currencyOption" class="form-control" readonly formControlName="totalSelecionado">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Status</label>
                                <input type="text" class="form-control" readonly formControlName="status">
                            </div>
                        </div>
                    </form>
                </tab>
                <tab *ngIf="permiteAlteracaoGarantia">
                    <ng-template tabHeading>
                        Garantia <span class="badge badge-pill badge-soft-danger">{{ veiculosGarantia?.length }}</span>
                    </ng-template>
                    <div class="table-responsive">
                        <table class="table table-sm table-hover table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Ano</th>
                                    <th>Km</th>
                                    <th>Placa</th>
                                    <th style="width: 150px;">Preço</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="list">
                                <tr *ngFor="let item of garantiasDeEstoque; index as i">
                                    <td>{{ item.marca }}</td>
                                    <td>{{ item.modelo }}</td>
                                    <td>{{ item.anoModelo }}</td>
                                    <td>{{ item.hodometro }}</td>
                                    <td>{{ item.placa }}</td>
                                    <td *ngIf="!item.emGarantia">{{ item.preco | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                    <td *ngIf="item.emGarantia">
                                        <input type="text" currencyMask [options]="currencyOption" [(ngModel)]="item.precoInstituicaoFinanceira" (ngModelChange)="SelecionarGarantias()" class="form-control">
                                        <div *ngIf="0 >= item.precoInstituicaoFinanceira" class="text-danger">
                                            <div *ngIf="0 >= item.precoInstituicaoFinanceira">Campo obrigatório</div>
                                        </div>
                                    </td>
                                    <td class="text-right">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" [(ngModel)]="item.emGarantia" (change)="SelecionarGarantias()" class="custom-control-input" id="{{i}}">
                                            <label class="custom-control-label" for="{{i}}"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="font-weight-bold">TOTAL</td>
                                    <td class="font-weight-bold">{{ totalEmGarantia | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>                        
                    </div>
                </tab>
                <tab *ngIf="!permiteAlteracaoGarantia">
                    <ng-template tabHeading>
                        Garantia <span class="badge badge-pill badge-soft-danger">{{qtdGarantia}}</span>
                    </ng-template>
                    <div class="table-responsive">
                        <table class="table table-sm table-hover table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Ano</th>
                                    <th>Km</th>
                                    <th>Placa</th>
                                    <th class="text-right">Preço</th>
                                </tr>
                            </thead>
                            <tbody class="list">
                                <tr *ngFor="let item of garantias; index as i">
                                    <td>{{ item.marca }}</td>
                                    <td>{{ item.modelo }}</td>
                                    <td>{{ item.anoModelo }}</td>
                                    <td>{{ item.hodometro }}</td>
                                    <td>{{ item.placa }}</td>
                                    <td class="text-right">{{ (item.precoInstituicaoFinanceira || item.preco) | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div [ngClass]="{'show': this.veiculosGarantia?.length !==0 }" class="list-alert alert alert-dark alert-dismissible border fade" role="alert">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="listAlertCheckbox" checked disabled>
                                        <label class="custom-control-label text-white" for="listAlertCheckbox"><span class="list-alert-count">{{ this.veiculosGarantia?.length }}</span> garantia(s) selecionada(s)</label>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</div>
