<div class="modal-card card">
    <div class="card-header">
        <h4 class="card-header-title" id="exampleModalCenterTitle">
            Adicionar regra
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
</div>

<div class="modal-body">
    <form id="form_vehicle_out" [formGroup]="formGroup">
        <div class="form-row">
            <div class="form-group col-md-8">
                <label>Loja</label>
                <select class="custom-select" formControlName="lojaId" (change)="getContrato()" data-toggle="select">
                  <option value="">Selecione</option>
                  <option *ngFor="let item of lojas" [value]="item.id">{{item.razaoSocial}}</option>
                </select>
                <div *ngIf="submitted && f.lojaId.errors" class="text-danger">
                    <div *ngIf="f.lojaId.errors">Campo obrigatório</div>
                </div>
            </div>
            <div class="form-group col-md-4">
                <label>Contrato</label>
                <select class="custom-select" formControlName="contratoId" data-toggle="select">
                  <option value="">Selecione</option>
                  <option *ngFor="let item of contrato" [value]="item.id">{{item.numero}}</option>
                </select>
            </div>
        </div>

        <!-- <div class="form-row">
            
            <div class="form-group col-md-4">
                <label>Tabela de Referência</label>
                <select class="custom-select" formControlName="tabelaReferencia" data-toggle="select">
                <option value="0">FIPE</option>
              </select>
            </div>            
        </div> -->
        <div class="form-row">
            <div class="form-group col-md-6">
                <label title="Ordem de seleção automática de veículos para garantia por ano.">Seleção Automática por Ano</label>
                <select class="custom-select" formControlName="ordemSelecaoGarantiaAno" data-toggle="select" (change)="habilitarOrdenacaoPorPreco()">
                    <option [value]="0">Aleatório</option>
                    <option [value]="1">Crescente</option>
                    <option [value]="2">Decrescente</option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <label title="Ordem de seleção automática de veículos para garantia por preço.">Seleção Automática por Preço</label>
                <select class="custom-select" formControlName="ordemSelecaoGarantiaPreco" data-toggle="select">
                    <option [value]="0">Aleatório</option>
                    <option [value]="1">Crescente</option>
                    <option [value]="2">Decrescente</option>
                </select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-6">
                <label>Filtros</label>
                <bs-sortable [(ngModel)]="itemStringsLeft" [ngModelOptions]="{standalone: true}" fieldName="name" itemClass="sortable-item" itemActiveClass="sortable-item-active" placeholderItem="Desliza aquí" placeholderClass="placeholderStyle" wrapperClass="sortable-wrapper"></bs-sortable>
            </div>
            <div class="form-group col-md-6">
                <label>&nbsp;</label>
                <bs-sortable [(ngModel)]="itemStringsRight" [ngModelOptions]="{standalone: true}" fieldName="name" itemClass="sortable-item" itemActiveClass="sortable-item-active" placeholderItem="Desliza aquí" placeholderClass="placeholderStyle" wrapperClass="sortable-wrapper"></bs-sortable>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white" data-dismiss="modal" (click)="bsModalRef.hide()">Cancelar</button>
    <button type="submit" form="form_vehicle_out" class="btn btn-primary" (click)="Agregar()">Adicionar regra</button>
</div>
