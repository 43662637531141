import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  ApiClient,
  ListarNotificacoesResponseItem
  } from 'src/app/core/http/api-client.generated';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-notificacaoes',
  templateUrl: './notificacaoes.component.html',
  styleUrls: ['./notificacaoes.component.css']
})
export class NotificacaoesComponent implements OnInit {

  notificacoes: ListarNotificacoesResponseItem[];
  bsModalRef: BsModalRef;

  constructor(private apiClient: ApiClient,
              private modalService: BsModalService,
              private authService: AuthService) {
    }

  ngOnInit(): void {
    this.getnotificacao();
  }

  getnotificacao() {
    this.apiClient.notificacoes(Number(this.authService.estabelecimentoId)).subscribe((resp) => {
      this.notificacoes = resp.notificacoes;
    });
  }

  detalheNotificacao(item, lida) {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, { initialState: {
      titulo: item.titulo,
      mensaje: item.conteudo,
      textCancelar: 'NÃO',
      textConfirmar: 'OK',
      showCancel: false
    }, class: 'modal-sm'});

    this.bsModalRef.content.event.subscribe(res => {
      if (res && lida) {
        this.apiClient.ler(item.id, Number(this.authService.estabelecimentoId)).subscribe((resp) => {
          this.getnotificacao();
        });
      }
    });
  }

}
