import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import {
  ApiClient,
  ListarExtratoFinanceiroResponseItem,
  ListarInstituicaoFinanceiraResponse,
  ListarInstituicaoFinanceiraResponseItem
} from 'src/app/core/http/api-client.generated';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AuthService } from 'src/app/core/auth/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

defineLocale('ptbr', ptBrLocale);

@Component({
  selector: 'app-extrato-financeiros',
  templateUrl: './extrato-financeiros.component.html',
  styleUrls: ['./extrato-financeiros.component.css']
})
export class ExtratoFinanceirosComponent implements OnInit {
  formulario: FormGroup;
  extrato: ListarExtratoFinanceiroResponseItem[] = [];
  daterangepickerModel: Date[];
  instituicoesFinanceiras: ListarInstituicaoFinanceiraResponseItem[] = [];

  constructor(private apiClient: ApiClient,
              private localeService: BsLocaleService,
              public authService: AuthService,
              private toastr: ToastrService) {
      this.localeService.use('ptbr');
      this.formulario = new FormGroup({
        data: new FormControl(null, Validators.required),
        instituicaoFinanceiraId: new FormControl(0, Validators.required)
      });
    } 

  ngOnInit(): void {
    if (this.authService.estabelecimentoId > 0) {
      this.formulario.get('instituicaoFinanceiraId').setValue(this.authService.estabelecimentoId);
    } else {
      this.apiClient.instituicoesFinanceiras().subscribe(response => this.instituicoesFinanceiras = response.instituicoesFinanceiras);    
    }

    this.formulario.get('data').setValue([
      moment().startOf('month').toDate(),
      moment().endOf('month').toDate()
    ]);
  }

  getExtrato() {
    if (this.formulario.get('instituicaoFinanceiraId').value == 0) {
      this.toastr.error('Selecione a instituição financeira.');
      return;
    }

    if (this.formulario.get('data').value == null) {
      this.toastr.error('Selecione o período.');
      return;
    }

    this.apiClient.extratoFinanceiro(this.formulario.get('instituicaoFinanceiraId').value, this.formulario.get('data').value[0], this.formulario.get('data').value[1]).subscribe((resp) => {
      this.extrato = resp.itens;
      if (resp.itens?.length == 0) {
        this.toastr.info('A consulta não retornou nenhum registro.');
      }
    });
  }

  get totalQuantidade(): number {
    return this.extrato.map(e => e.quantidade).reduce((valor, soma) => valor + soma, 0);
  }

  get totalValor(): number {
    return this.extrato.map(e => e.valorTotal).reduce((valor, soma) => valor + soma, 0);
  }
}
