<div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8 col-lg-6 col-xl-5 px-4">
                <div class="text-center mb-5">
                    <img src="assets/img/logo.png" class="img-fluid" alt="ControlStock">
                </div>
                <form [formGroup]="formGroup" class="needs-validation">
                    <div class="form-group">
                        <label>CPF</label>
                        <input type="text" mask="000.000.000-00" placeholder="Digite seu CPF" formControlName="cpf" class="form-control">
                        <div *ngIf="submitted && f.cpf.errors" class="text-danger">
                            <div *ngIf="f.cpf.errors">Campo obrigatório</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col">
                                <label>Senha</label>
                            </div>
                        </div>
                        <!-- / .row -->
                        <div class="input-group input-group-merge">
                            <input type="password" formControlName="senha" class="form-control" placeholder="Digite sua senha">
                        </div>
                        <div *ngIf="submitted && f.senha.errors" class="text-danger">
                            <div *ngIf="f.senha.errors">Campo obrigatório</div>
                        </div>
                    </div>
                    <button class="btn btn-lg btn-block btn-primary mb-3" (click)="onSubmit()">Entrar</button>
                </form>
            </div>
        </div>
    </div>
</div>