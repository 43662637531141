<nav class="navbar navbar-expand-md navbar-light d-none d-md-flex" id="topbar">
    <div class="container-fluid">
        <form class="form-inline mr-4 d-none d-md-flex">
        </form>
        <div class="navbar-user">
            <div class="mr-3">
            </div>
            <div class="btn-group mr-4 d-md-flex" dropdown>
                <a dropdownToggle class="navbar-user-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span [ngClass]="{'active': msjNoLidas}" class="icon "><i class="fe fe-bell"></i></span>
                </a>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-card">
                    <div class="card-body">
                        <div class="list-group list-group-flush my-n3">
                            <a class="list-group-item text-reset" *ngFor="let item of notificacoes">
                                <div class="row">
                                    <div class="col-auto cursor-pointer" (click)="detalheNotificacao(item)">
                                        <div class="small">
                                            <strong>{{item.titulo}}</strong>
                                        </div>
                                        <small class="text-muted">{{ item.data | date:'dd/MM/yyyy hh:mm' }}</small>
                                    </div>
                                </div>
                            </a>
                            <a class="list-group-item text-reset">
                                <div class="text-center">
                                    <div class="">
                                        <div class="small">
                                            <button routerLink='/notificacao' class="btn btn-block btn-light">
                                              <strong>Ver todas</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn-group mr-4 d-md-flex" dropdown>
                <a dropdownToggle class="navbar-user-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="icon "><i class="fe fe-user"></i></span>
                </a>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-card">
                    <div class="card-body">
                        <div class="list-group list-group-flush my-n3">
                            <a class="list-group-item text-reset">
                                <div class="row">
                                    <div class="col-auto cursor-pointer" (click)="logout()">
                                        <div class="small">
                                            <strong>SAIR</strong>
                                        </div>
                                        <!-- <small class="text-muted">Encerrar sessão</small> -->
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>