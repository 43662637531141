<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col-9">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Situação Atual dos Contratos
                    </h1>
                </div>
                <div class="col text-right">
                    <a routerLink="/relatorios/situacao" class="btn btn-dark">
                        <span class="fe fe-arrow-left"></span>
                        <span class="d-none d-md-inline">Voltar</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-12 col-lg-12 col-xl-12">
            <tabset class="tab-content">
                <tab heading="Contrato">
                    &nbsp;
                    <form>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Loja</label>
                                <input type="text" name="loja" class="form-control" readonly [(ngModel)]="contrato.loja">
                            </div>
                            <div class="form-group col-md-3">
                                <label>Nº</label>
                                <input type="text" name="numero" class="form-control" readonly [(ngModel)]="contrato.numero">
                            </div>
                            <div class="form-group col-md-3">
                                <label>Nº Geero</label>
                                <input type="text" name="idGeero" class="form-control" readonly [(ngModel)]="contrato.idGeero">
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label>Tipo do Contrato</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input disabled [(ngModel)]="contrato.tipo" class="form-check-input" type="radio" value="Rotativo" name="tipoContrato" id="tipo1">
                                        <label class="form-check-label" for="tipo1">Rotativo</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input disabled [(ngModel)]="contrato.tipo" class="form-check-input" type="radio" value="Parcelado" name="tipoContrato" id="tipo2">
                                        <label class="form-check-label" for="tipo2">Parcelado</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label>Data de Término</label>
                                <input readonly type="text" name="dataTermino" [value]="dataTermino | date:'dd/MM/yyyy'" class="form-control">
                            </div>
                            <div class="form-group col-md-3">
                                <label>Valor do Crédito</label>
                                <input readonly type="text" name="valorCredito" currencyMask [options]="currencyOption" [(ngModel)]="contrato.valorCredito" class="form-control">
                            </div>

                            <div class="form-group col-md-3">
                                <label>Valor da Garantia</label>
                                <input readonly type="text" name="valorGarantia" currencyMask [options]="currencyOption" [(ngModel)]="contrato.valorGarantia" class="form-control">
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Status</label>
                                <input type="text" name="status" class="form-control" readonly [(ngModel)]="contrato.status">
                            </div>
                        </div>
                    </form>
                </tab>
                <tab heading="Garantia">
                    <div class="table-responsive">
                        <table class="table table-sm table-hover table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Ano</th>
                                    <th>Km</th>
                                    <th>Placa</th>
                                    <th class="text-right">Preço</th>
                                </tr>
                            </thead>
                            <tbody class="list">
                                <tr *ngFor="let item of garantia">
                                    <td>{{item.marca}}</td>
                                    <td>{{item.modelo}}</td>
                                    <td>{{item.anoModelo}}</td>
                                    <td>{{item.hodometro | mask: 'separator':'.'}}</td>
                                    <td>{{item.placa}}</td>
                                    <td class="text-right">{{(item.precoInstituicaoFinanceira || item.precoVeiculo) | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="font-weight-bold">TOTAL</td>
                                    <td class="font-weight-bold text-right">{{ totalEmGarantia | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
                <tab heading="Histórico de Movimentações">
                    <table class="table table-sm table-hover table-nowrap card-table">
                        <thead>
                            <tr>
                                <th>Marca</th>
                                <th>Modelo</th>
                                <th>Placa</th>
                                <th>Data Inclusão</th>
                                <th>Data Exclusão</th>
                            </tr>
                        </thead>
                        <tbody class="list">
                            <tr *ngFor="let item of historico">
                                <td>{{item.marca}}</td>
                                <td>{{item.modelo}}</td>
                                <td>{{item.placa}}</td>
                                <td>{{item.dataInclusao | date:'dd/MM/yyyy'}}</td>
                                <td>{{item.dataExclusao | date:'dd/MM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </tab>
            </tabset>            
        </div>
    </div>
</div>