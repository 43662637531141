<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col-9">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Contratos
                    </h1>
                </div>
                <div class="col text-right">
                    <a routerLink='/contratos' class="btn btn-dark">
                        <span class="fe fe-arrow-left"></span>
                        <span class="d-none d-md-inline">Voltar</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row mt-2">
        <div class="col text-right mb-1" *ngIf="tipo=='criar'">
            <a class="btn btn-primary lift mr-1 d-block d-md-inline" (click)="salvarRascunho()">
                <span class="fe fe-save"></span>
                Salvar rascunho
            </a>
        </div>
    </div>
    <div class="row align-items-center">
        <div class="col">
            <tabset class="tab-content">
                <tab heading="Dados">
                    <form [formGroup]="formGroup">
                        <div class="form-row mt-1">
                            <div class="form-group col-md-6">
                                <label>Loja</label>
                                <select class="form-control" formControlName="lojaId" (change)="getGarantias(formGroup.value.lojaId)" name="lojaId">
                                  <option value="">Selecione</option>
                                  <option *ngFor="let item of lojas" [value]='item.id'>{{item.razaoSocial}}</option>
                                </select>
                                <div *ngIf="submitted && f.lojaId.errors" class="text-danger">
                                    <div *ngIf="f.lojaId.errors">Campo obrigatório</div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label>Número</label>
                                <input type="text" formControlName="numero" class="form-control">
                                <div *ngIf="submitted && f.numero.errors" class="text-danger">
                                    <div *ngIf="f.numero.errors">Campo obrigatório</div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label>Regra de Garantia</label>
                                <select formControlName="regraGarantiaId" class="form-control" (change)="getGarantias(formGroup.value.lojaId)">
                                    <option *ngFor="let r of regras" [value]="r.id">{{ r.aplicacao }}</option>
                                    <option [value]="0">Nenhuma</option>
                                </select>
                                <div *ngIf="submitted && f.regraGarantiaId.errors" class="text-danger">
                                    <div *ngIf="f.regraGarantiaId.errors">Campo obrigatório</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-3">
                                <label>Tipo do Contrato</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input formControlName="tipo" class="form-check-input" type="radio" value="1" name="tipo" id="tipo1">
                                        <label class="form-check-label" for="tipo1">Rotativo</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input formControlName="tipo" class="form-check-input" type="radio" value="0" name="tipo" id="tipo2">
                                        <label class="form-check-label" for="tipo2">Parcelado</label>
                                    </div>

                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label>Data de Término</label>
                                <input type="text" formControlName="dataTermino" class="form-control" bsDatepicker [minDate]="minDate" [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-default',dateInputFormat: 'DD/MM/YYYY' }" [bsConfig]="{ adaptivePosition: true }">
                                <div *ngIf="submitted && f.dataTermino.errors" class="text-danger">
                                    <div *ngIf="f.dataTermino.errors">Campo obrigatório</div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label>Valor do Crédito</label>
                                <input type="text" currencyMask [options]="currencyOption" formControlName="valorCredito" class="form-control">
                                <div *ngIf="submitted && f.valorCredito.errors" class="text-danger">
                                    <div *ngIf="f.valorCredito.errors">Campo obrigatório</div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label>Valor da Garantia</label>
                                <input type="text" currencyMask [options]="currencyOption" formControlName="valorGarantia" class="form-control">
                                <div *ngIf="submitted && f.valorGarantia.errors" class="text-danger">
                                    <div *ngIf="f.valorGarantia.errors">Campo obrigatório</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <label>Seleção de Garantia</label>
                                <div>
                                    <div class="form-check form-check-inline">
                                        <input formControlName="tipoSelecaoGarantia" class="form-check-input" type="radio" name="tipoSelecaoGarantia" value="0" id="garantia1">
                                        <label class="form-check-label" for="garantia1">Loja</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input formControlName="tipoSelecaoGarantia" class="form-check-input" type="radio" name="tipoSelecaoGarantia" value="1" id="garantia3">
                                        <label class="form-check-label" for="garantia3">Instituição Financeira</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input formControlName="tipoSelecaoGarantia" class="form-check-input" type="radio" name="tipoSelecaoGarantia" value="2" id="garantia2">
                                        <label class="form-check-label" for="garantia2">Automático</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </tab>
                <tab *ngIf="formGroup.value.tipoSelecaoGarantia === '1' && formGroup.value.regraGarantiaId !== null">
                    <ng-template tabHeading>
                        Garantia <span class="badge badge-pill badge-soft-danger">{{qtdGarantia}}</span>
                    </ng-template>
                    <div class="table-responsive">
                        <table class="table table-sm table-hover table-nowrap card-table">
                            <thead>
                                <tr>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Ano</th>
                                    <th>Km</th>
                                    <th>Placa</th>
                                    <th style="width: 140px;">Preço</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="list">
                                <tr *ngFor="let item of garantias; index as i">
                                    <td class="">{{ item.marca }}</td>
                                    <td class="">{{ item.modelo }}</td>
                                    <td class="">{{ item.anoModelo }}</td>
                                    <td class="">{{ item.hodometro }}</td>
                                    <td class="">{{ item.placa }}</td>
                                    <td *ngIf="!item.emGarantia" class="">{{item.preco | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                    <td *ngIf="item.emGarantia" class="">
                                        <input type="text" currencyMask [options]="currencyOption" [(ngModel)]="item.precoInstituicaoFinanceira" (ngModelChange)="SelecionarGarantias()" class="form-control">
                                        <div *ngIf="0 >= item.precoInstituicaoFinanceira" class="text-danger">
                                            <div *ngIf="0 >= item.precoInstituicaoFinanceira">Campo obrigatório</div>
                                        </div>
                                    </td>
                                    <td class="text-right">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" [(ngModel)]="item.emGarantia" (change)="SelecionarGarantias()" class="custom-control-input" id="{{i}}">
                                            <label class="custom-control-label" for="{{i}}"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="font-weight-bold">TOTAL</td>
                                    <td class="font-weight-bold">{{ totalEmGarantia | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </tab>
            </tabset>            
        </div>
    </div>
</div>
