<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Notificações
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="table-responsive-sm">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr class="d-flex">
                                        <th class="col-9">Notificação</th>
                                        <th class="col-2 d-none d-md-table-cell">Data</th>
                                        <th class="col-sm-1 d-none d-md-table-cell">Lido</th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr class="d-flex cursor-pointer" *ngFor="let item of notificacoes; index as i" (click)="detalheNotificacao(item, true)">
                                        <td class="col-9">
                                            <span class="d-none d-md-block">{{ item.titulo }}</span>
                                            <span class="d-block d-md-none" [ngClass]="{ 'font-weight-bold': !item.lida }">{{ item.titulo }}</span>
                                            <small class="text-muted d-block d-md-none">{{ item.data | date:'dd/MM/yyyy hh:mm' }}</small>
                                        </td>
                                        <td class="col-2 d-none d-md-table-cell">{{ item.data | date:'dd/MM/yyyy hh:mm' }}</td>
                                        <td class="col-sm-1 text-center custom-control custom-checkbox d-none d-md-table-cell">
                                            <input type="checkbox" [(ngModel)]="item.lida"  class="custom-control-input" id="{{i}}" [disabled]="true">
                                            <label class="custom-control-label" for="{{i}}"></label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>