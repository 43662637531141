import {​ Directive, Input, ElementRef, TemplateRef, ViewContainerRef, OnInit }​ from '@angular/core';
import { AuthService } from '../core/auth/auth.service';

@Directive({​
    selector: '[permissao]'
}​)
export class PermissaoDirective implements OnInit {​
    @Input() permissao: string;
    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private authService: AuthService) {​
    }​
    
    ngOnInit() {​
        if (this.authService.permissoes?.some(a => a.startsWith(this.permissao))) {​
            this.viewContainer.createEmbeddedView(this.templateRef);
        }​ else {​
            this.viewContainer.clear();
        }​
    }​
}​