import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContratosListarComponent } from './contratos-listar/contratos-listar.component';
import { ContratosCriarComponent } from './contratos-criar/contratos-criar.component';
import { ContratosDetalhesComponent } from './contratos-detalhes/contratos-detalhes.component';
import { PermissaoGuard } from 'src/app/core/guards/permissao.guard';

const routes: Routes = [
  {
    path: 'listar',
    component: ContratosListarComponent,
    data: { permissao: 'Contratos.Contratos.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'detalhe/:id',
    component: ContratosDetalhesComponent,
    data: { permissao: 'Contratos.Contratos.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'criar',
    component: ContratosCriarComponent,
    data: { permissao: 'Contratos.Contratos.Criar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: '**',
    redirectTo: 'listar',
    pathMatch: 'full',
    data: { permissao: 'Contratos.Contratos.Listar' },
    canActivate: [PermissaoGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContratosRoutingModule { }
