import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {
  ApiClient,
  IListarLojaResponseItem,
  ListarContratosResponseItem,
  RegraGarantiaFiltroTipo,
  IncluirRegraGarantiaRequestFiltro,
  OrdemSelecaoGarantiaAutomatica
} from 'src/app/core/http/api-client.generated';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-reglas-criar',
  templateUrl: './regras-criar.component.html',
  styleUrls: ['./regras-criar.component.css']
})

export class RegrasCriarComponent implements OnInit {

  formGroup!: FormGroup;
  submitted = false;
  lojas!: IListarLojaResponseItem[];
  contrato: ListarContratosResponseItem[];
  filtros: IncluirRegraGarantiaRequestFiltro[] = [];
  public event: EventEmitter<any> = new EventEmitter();

  itemStringsLeft: any[] = [
    { id: RegraGarantiaFiltroTipo._0, name: 'Marca'},
    { id: RegraGarantiaFiltroTipo._1, name: 'Modelo'},
    { id: RegraGarantiaFiltroTipo._2, name: 'Tipo de Veículo'},
    { id: RegraGarantiaFiltroTipo._3, name: 'UF'},
    { id: RegraGarantiaFiltroTipo._4, name: 'Tipo de Combustivel'},
    { id: RegraGarantiaFiltroTipo._5, name: 'Idade Máxima'},
    { id: RegraGarantiaFiltroTipo._6, name: 'Km Máxima'},
    { id: RegraGarantiaFiltroTipo._7, name: 'Chassi Remarcado'},
    { id: RegraGarantiaFiltroTipo._8, name: 'Leilão'}
  ];

  itemStringsRight = [];

  constructor(public bsModalRef: BsModalRef,
              private formBuilder: FormBuilder,
              private apiClient: ApiClient,
              private toastr: ToastrService,
              private authService: AuthService) {
  }

  ngOnInit(): void {

    this.formGroup = this.formBuilder.group({
      lojaId: [null],
      contratoId: [null],
      tabelaReferencia: [0, Validators.required],
      instituicaoFinanceiraId: [null],
      ordemSelecaoGarantiaAno: [0],
      ordemSelecaoGarantiaPreco: [0],
      filtros: [[]]
    });

    this.getLojas(this.authService.estabelecimentoId);

    this.habilitarOrdenacaoPorPreco();
  }

  get f() { return this.formGroup.controls; }

  habilitarOrdenacaoPorPreco()  {
    if (this.formGroup.controls.ordemSelecaoGarantiaAno.value == 0) {
      this.formGroup.controls.ordemSelecaoGarantiaPreco.setValue(0);
      this.formGroup.controls.ordemSelecaoGarantiaPreco.disable();
    } else {
      this.formGroup.controls.ordemSelecaoGarantiaPreco.enable();
    }
  }

  Agregar() {

    this.submitted = true;
    if (!this.formGroup.valid) {
      return;
    }

    if (!this.ValidModelosMarca()) {
      this.toastr.error('Modelo não pode ir sem Marca.');
      return;
    }

    this.itemStringsRight.forEach((element, i) => {
      let item: IncluirRegraGarantiaRequestFiltro = new IncluirRegraGarantiaRequestFiltro();
      item.filtro = element.id;
      item.ordem = i + 1;
      this.filtros.push(item);
    });

    this.formGroup.value.filtros = this.filtros;

    this.event.emit(this.formGroup.value);
    this.bsModalRef.hide();
  }

  getLojas(id) {
    // tslint:disable-next-line: radix
    this.apiClient.whitelist(parseInt(id)).subscribe( (resp) => {
      this.lojas = resp.lojas;
    });
  }

  getContrato() {
    // tslint:disable-next-line: radix
    this.apiClient.contratos4(Number(this.authService.estabelecimentoId), parseInt(this.formGroup.value.lojaId)).subscribe( (resp) => {
      this.contrato = resp.contratos;
    });
  }

  ValidModelosMarca(){
    var marca = this.itemStringsRight.findIndex(item => item.name == 'Marca');
    var modelo = this.itemStringsRight.findIndex(item => item.name == 'Modelo');
    var valid = true;
    if( (marca === -1 && modelo !== -1) || (marca !== -1 && modelo === -1) ){
      if(marca === -1 && modelo !== -1) {
        valid = false
      }
    }

    return valid;
  }

}
