import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WhitelistCriarComponent } from '../whitelist-criar/whitelist-criar.component';
import { AlterarPrazoWhitelistRequest, ApiClient, ListarWhitelistsResponseItem} from 'src/app/core/http/api-client.generated';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.css']
})
export class WhitelistComponent implements OnInit {

  bsModalRef: BsModalRef;
  whitelist!: ListarWhitelistsResponseItem[];

  constructor(
    private modalService: BsModalService,
    private apiClient: ApiClient,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadWhitelist();
  }

  ExcluirWhitelist(id) {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, { initialState: {
      titulo: 'Excluir whitelist',
      mensaje: 'Deseja excluir este registro da whitelist?',
      textCancelar: 'NÃO',
      textConfirmar: 'SIM'
    }, class: 'modal-sm'});

    this.bsModalRef.content.event.subscribe(res => {
      this.apiClient.whitelist6(id).subscribe((resp) => {
        this.toastr.success('Excluído com sucesso');
        this.loadWhitelist();
      });
    });

  }

  NovoWhitelist() {
    this.bsModalRef = this.modalService.show(WhitelistCriarComponent);

    this.bsModalRef.content.event.subscribe(res => {

      this.apiClient.whitelist3(res).subscribe((resp) => {
        this.toastr.success('Adicionado com sucesso');
        this.loadWhitelist();
      });
    });
  }

  AlterarWhitelist(item) {
    this.bsModalRef = this.modalService.show(WhitelistCriarComponent, {
      initialState: {
        tipo: 'alterar',
        data: item
      }
    });

    this.bsModalRef.content.event.subscribe(res => {
      this.apiClient.whitelist5(item.id, new AlterarPrazoWhitelistRequest({ id: item.id, prazo: moment(res.prazo)})).subscribe((resp) => {
        this.loadWhitelist();
      });
    });
  }



  loadWhitelist() {
    this.apiClient.whitelist4().subscribe( (resp) => {
      this.whitelist = resp.whiteLists;
    });
  }

}
