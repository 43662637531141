import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './components/home/home.component';
import { NavRightComponent } from './shared/layout/nav-right/nav-right.component';
import { LoginComponent } from './components/login/login.component';

import { AuthService } from './core/auth/auth.service';
import { AuthGuard } from './core/auth/auth.guard';
import { NavComponent } from './shared/layout/nav/nav.component';
import { RelatorioModule} from './components/relatorios/relatorio.module';
import { ConfiguracoesModule} from './components/configuracoes/configuracoes.module';
import { ContratosModule } from './components/contratos/contratos.module';
import { ModalConfirmComponent } from './shared/layout/modal-confirm/modal-confirm.component';
import { API_BASE_URL, ApiClient} from './core/http/api-client.generated';
import { environment } from 'src/environments/environment';
import { ChartsModule } from 'ng2-charts';
import { registerLocaleData } from '@angular/common';
import { NgxCurrencyModule } from 'ngx-currency';
import localePt from '@angular/common/locales/pt';
import { NotificacaoesComponent } from './components/notificacoes/notificacaoes.component';
import { AuthInterceptor } from './core/http/http.interceptor';
import { PermissaoDirective } from './directives/permissao.directive';
import { ModalConfirmComentarioComponent } from './shared/layout/modal-confirm-comentario/modal-confirm-comentario.component';
registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavRightComponent,
    LoginComponent,
    NavComponent,
    ModalConfirmComponent,
    NotificacaoesComponent,
    PermissaoDirective,
    ModalConfirmComentarioComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    RelatorioModule,
    ConfiguracoesModule,
    ContratosModule,
    ChartsModule,
    NgxCurrencyModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxMaskModule
  ],
  exports: [
    PermissaoDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthService,
    AuthGuard,
    { provide: API_BASE_URL, useValue: environment.apiBaseUrl },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    ApiClient],
  bootstrap: [AppComponent]
})
export class AppModule { }
