<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Regras de Garantia
                    </h1>
                </div>
                <div class="col-auto">
                    <a (click)="AdicionarRegra()" data-toggle="modal" class="btn btn-primary lift">
                        <span class="fe fe-plus"></span>
                        <span class="d-none d-md-inline">Adicionar regra</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>Aplicação</th>
                                        <th>Loja</th>
                                        <th>Contrato</th>
                                        <th class="text-center">Tabela de Referência</th>
                                        <th class="text-center">Ordem Seleção Garantia</th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr *ngFor="let item of reglas; let i = index" class="cursor-pointer" (click)="goDetalheItem(item)">
                                        <td class="">{{item.aplicacao}}</td>
                                        <td class="">{{item.loja}}</td>
                                        <td class="">{{item.contrato}}</td>
                                        <td class="text-center">{{['FIPE'][item.tabelaReferencia]}}</td>
                                        <td class="text-center">
                                            <span *ngIf="item.ordemSelecaoGarantiaAno == 0">Aleatório</span>
                                            <span *ngIf="item.ordemSelecaoGarantiaAno != 0">Ano</span>
                                            <span class="fe fe-arrow-up" *ngIf="item.ordemSelecaoGarantiaAno == 1" title="Ano crescente"></span>
                                            <span class="fe fe-arrow-down" *ngIf="item.ordemSelecaoGarantiaAno == 2"  title="Ano decrescente"></span>
                                            <span *ngIf="item.ordemSelecaoGarantiaPreco != 0">Preço</span>
                                            <span class="fe fe-arrow-up" *ngIf="item.ordemSelecaoGarantiaPreco == 1"  title="Preço crescente"></span>
                                            <span class="fe fe-arrow-down" *ngIf="item.ordemSelecaoGarantiaPreco == 2"  title="Preço decrescente"></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
