<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Histórico de Precificação
                    </h1>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col">
                                    <form [formGroup]="formulario">
                                        <div class="input-group input-group-flush">
                                            <select formControlName="instituicaoFinanceiraId" class="form-control" *ngIf="authService.estabelecimentoId == 0">
                                                <option [value]="0">Selecione a Instituição Financeira</option>
                                                <option *ngFor="let instituicao of instituicoesFinanceiras" [value]="instituicao.id">{{ instituicao.razaoSocial }}</option>
                                            </select>                                            
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fe fe-search"></i></span>
                                            </div>
                                            <input class="form-control" type="text" placeholder="Buscar veículo por placa, modelo ou marca" formControlName="veiculo">
                                            <button class="btn btn-outline-info" (click)="resetBusca()">
                                                <span class="fe fe-search"></span>
                                                Buscar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>Marca</th>
                                        <th>Modelo</th>
                                        <th>Ano</th>
                                        <th>Km</th>
                                        <th>Placa</th>
                                        <th class="text-right">Preço</th>
                                        <th style="cursor: pointer;" *ngIf="campoOrdenacao != 'loja'" (click)="sort('loja', 'asc')">Loja <i class="pi pi-sort-alt"></i></th>
                                        <th style="cursor: pointer;" *ngIf="campoOrdenacao == 'loja' && ordenacao == 'desc'" (click)="sort('loja', 'asc')">Loja <i class="pi pi-sort-amount-down"></i></th>
                                        <th style="cursor: pointer;" *ngIf="campoOrdenacao == 'loja' && ordenacao == 'asc'" (click)="sort('loja', 'desc')">Loja <i class="pi pi-sort-amount-down-alt"></i></th>
                                        <th style="cursor: pointer;" *ngIf="campoOrdenacao != 'contratoId'" (click)="sort('contratoId', 'asc')">Contrato <i class="pi pi-sort-alt"></i></th>
                                        <th style="cursor: pointer;" *ngIf="campoOrdenacao == 'contratoId' && ordenacao == 'desc'" (click)="sort('contratoId', 'asc')">Contrato <i class="pi pi-sort-amount-down"></i></th>
                                        <th style="cursor: pointer;" *ngIf="campoOrdenacao == 'contratoId' && ordenacao == 'asc'" (click)="sort('contratoId', 'desc')">Contrato <i class="pi pi-sort-amount-down-alt"></i></th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr *ngFor="let item of precificacoes" (click)="abrirDetalhe(item.veiculoId)" style="cursor: pointer;">
                                        <td class="">{{item.marca}}</td>
                                        <td class="">{{item.modelo}}</td>
                                        <td class="">{{item.anoModelo}}</td>
                                        <td class="">{{item.hodometro | mask: 'separator':'.'}}</td>
                                        <td class="">{{item.placa}}</td>
                                        <td class="text-right">{{item.preco | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td class="">{{item.loja}}</td>
                                        <td class="">{{item.numeroContrato}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p-paginator *ngIf="total > 0" [rows]="rows" [first]="first" [totalRecords]="total" (onPageChange)="paginate($event)"></p-paginator>
                    </div>

                </div>
            </div>
        </div>
    </div>


</div>
