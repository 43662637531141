import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ParametrosComponent } from './parametros-comerciais/parametros/parametros.component';
import { WhitelistComponent } from './whitelists/whitelist/whitelist.component';
import { ConfiguracoesRoutingModule } from './configuracoes-routing.module';
import { ParametrosDetailComponent } from './parametros-comerciais/parametros-detail/parametros-detail.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ParametrosCriarComponent } from './parametros-comerciais/parametros-criar/parametros-criar.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { WhitelistCriarComponent } from './whitelists/whitelist-criar/whitelist-criar.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { RegrasListarComponent } from './regras-garantia/regras-listar/regras-listar.component';
import { RegrasCriarComponent } from './regras-garantia/regras-criar/regras-criar.component';
import { RegraItemsComponent } from './regras-garantia/regra-items/regra-items.component';
import { RegraItemsCriarComponent } from './regras-garantia/regra-items-criar/regra-items-criar.component';
import { RegraDetalhesComponent } from './regras-garantia/regra-detalhes/regra-detalhes.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CookieModule } from 'ngx-cookie';
import { PermissaoGuard } from 'src/app/core/guards/permissao.guard';
import { SortableModule } from 'ngx-bootstrap/sortable';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({ 
  declarations: [
    ParametrosComponent,
    WhitelistComponent,
    ParametrosDetailComponent,
    ParametrosCriarComponent,
    WhitelistCriarComponent,
    RegrasListarComponent,
    RegrasCriarComponent,
    RegraItemsComponent,
    RegraItemsCriarComponent,
    RegraDetalhesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ConfiguracoesRoutingModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(),
    NgSelectModule,
    CookieModule.forRoot(),
    SortableModule.forRoot(),
  ],
  providers: [
    PermissaoGuard,
  ]
})

export class ConfiguracoesModule {

}
