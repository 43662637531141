<div class="modal-card card">
    <div class="card-header">
        <h4 class="card-header-title" id="exampleModalCenterTitle">
            {{tipo == 'editar' ? 'Editar parâmetros comerciais' : 'Cadastrar parâmetros comerciais'}}
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
</div>

<div class="modal-body">
    <form id="form_vehicle_out" [formGroup]="formGroup">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label>Loja</label>
                <select class="custom-select" formControlName="lojaId" (change)="getContrato()" data-toggle="select">
                  <option disabled value="">Selecione</option>
                  <option *ngFor="let item of lojas" [value]="item.id">{{item.razaoSocial}}</option>
                </select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <label>Contrato</label>
                <select class="custom-select" formControlName="contratoId" data-toggle="select">
                  <option disabled value="">Selecione</option>
                  <option *ngFor="let item of contrato" [value]="item.id">{{ item.numero }}</option>
                </select>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-6">
                <label>Contrato incluído</label>
                <input type="text" class="form-control" currencyMask [options]="currencyOption" formControlName='contratoNovo'>
            </div>

            <div class="form-group col-md-6">
                <label>Contrato alterado</label>
                <input type="text" class="form-control" currencyMask [options]="currencyOption" formControlName="contratoAlterado">
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-6">
                <label>Garantia incluída</label>
                <input type="text" class="form-control" currencyMask [options]="currencyOption" formControlName='garantiaIncluida'>
            </div>

            <div class="form-group col-md-6">
                <label>Garantia removida</label>
                <input type="text" class="form-control" currencyMask [options]="currencyOption" formControlName="garantiaRemovida">
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-6">
                <label>Regra de garantia alterada</label>
                <input type="text" class="form-control" currencyMask [options]="currencyOption" formControlName='regraGarantiaAlterada'>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white" data-dismiss="modal" (click)="bsModalRef.hide()">Cancelar</button>
    <button type="submit" form="form_vehicle_out" class="btn btn-primary" (click)="Agregar()">Salvar</button>
</div>
