import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';
import { ParametrosCriarComponent } from '../parametros-criar/parametros-criar.component';
import { ActivatedRoute } from '@angular/router';
import { ApiClient, ListarParametroComercialResponseItem} from 'src/app/core/http/api-client.generated';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-parametros-detail',
  templateUrl: './parametros-detail.component.html',
  styleUrls: ['./parametros-detail.component.css']
})
export class ParametrosDetailComponent implements OnInit {

  bsModalRef: BsModalRef;
  InstituicaoFinanceiraId: string;

  public comerciais!: ListarParametroComercialResponseItem[];

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private apiClient: ApiClient,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.InstituicaoFinanceiraId = params.id;
    });

    this.CargarParametrosComerciais();
  }

  AdicionarParametro() {
    this.bsModalRef = this.modalService.show(ParametrosCriarComponent, {
      initialState: {data: {instituicaoFinanceiraId: this.InstituicaoFinanceiraId}}
    });

    this.bsModalRef.content.event.subscribe(res => {

      res.instituicaoFinanceiraId = parseInt(this.InstituicaoFinanceiraId);
      this.apiClient.parametrosComerciais5(res).subscribe( (resp) => {
        this.toastr.success('Adicionado com sucesso');
        this.CargarParametrosComerciais();
      });
    });
  }

  EditarParametro(item) {
    this.bsModalRef = this.modalService.show(ParametrosCriarComponent, {
      initialState: { tipo: 'editar', data: item}
    });

    this.bsModalRef.content.event.subscribe(res => {
      res.instituicaoFinanceiraId = parseInt(this.InstituicaoFinanceiraId);
      this.apiClient.parametrosComerciais2(item.id, res).subscribe( (resp) => {
        this.toastr.success('Atualizado com sucesso');
        this.CargarParametrosComerciais();
      });
    });
  }

  ExcluirParametro(indice) {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: {
        titulo: 'Excluir Parametro',
        mensaje: 'Deseja excluir este ítem?',
        textCancelar: 'NÃO',
        textConfirmar: 'SIM'
      }, class: 'modal-sm'});

    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        this.apiClient.parametrosComerciais3(Number(indice)).subscribe( (resp) => {
          this.toastr.success('Excluído com sucesso');
          this.CargarParametrosComerciais();
        }, (erro) => {
          if (erro.status === 409) {
            this.bsModalRef = this.modalService.show(ModalConfirmComponent, {
              initialState: {
                titulo: 'Atenção',
                mensaje: 'Existe um contrato ativo sendo afetado por este parâmetro, deseja excluir assim mesmo este ítem?',
                textCancelar: 'NÃO',
                textConfirmar: 'SIM'
              }, class: 'modal-sm'});

            this.bsModalRef.content.event.subscribe(res2 => {
              if (res) {
                this.apiClient.parametrosComerciais3(Number(indice), true).subscribe( (resp2) => {
                  this.toastr.success('Excluído com sucesso');
                  this.CargarParametrosComerciais();
                });
              }
            });
          }
        });
      }
    });

  }

  CargarParametrosComerciais() {
    this.apiClient.parametrosComerciais4(parseInt(this.InstituicaoFinanceiraId)).subscribe( (resp) => {
      this.comerciais = resp.parametrosComerciais;
    });
  }

}

