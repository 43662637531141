<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">

</div>