<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Itens da Regra de Garantia
                    </h1>
                </div>
                <div class="col-auto">
                    <a data-toggle="modal" class="btn btn-primary lift" (click)="AdicionarIten()">
                        <span class="d-md-none fe fe-plus"></span>
                        <span class="d-none d-md-block">Adicionar Itens</span>
                    </a>
                    &nbsp;
                    <a data-toggle="modal" routerLink="/configuracoes/regras-garantia" class="btn btn-dark lift">
                        <span class="d-md-none fe fe-arrow-left"></span>
                        <span class="d-none d-md-block">Voltar</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th *ngIf="findValue(0)">Marca</th>
                                        <th *ngIf="findValue(1)">Modelo</th>
                                        <th *ngIf="findValue(5)">Idade</th>
                                        <th *ngIf="findValue(6)">Km</th>
                                        <th *ngIf="findValue(3)">UF</th>
                                        <th *ngIf="findValue(7)">Chassi</th>
                                        <th *ngIf="findValue(8)">Leilão</th>
                                        <th *ngIf="findValue(4)">Tipo Combustivel</th>
                                        <th *ngIf="findValue(2)">Tipo Veiculo</th>
                                        <th colspan="2">% Tabela</th>
                                    </tr>
                                </thead>
                                <tbody class="list">

                                    <tr *ngFor="let item of itens; let i = index">
                                        <td *ngIf="findValue(0)" class="">{{item.veiculoMarcas.length}} {{item.veiculoMarcas.length == 1 ? 'marca selecionada' : 'marcas selecionadas'}} </td>
                                        <td *ngIf="findValue(1)" class="">{{item.veiculoModelos == null ? 0 : item.veiculoModelos.length}} {{ item.veiculoModelos == null ? ' modelos selecionados' : (item.veiculoModelos.length == 1 ? 'modelo selecionado' : 'modelos selecionados')}}</td>
                                        <td *ngIf="findValue(5)" class="">{{item.idadeMaxima}}</td>
                                        <td *ngIf="findValue(6)" class="">{{item.quilometragemMaxima}}</td>
                                        <td *ngIf="findValue(3)" class="">{{item.veiculoUFs.length}} {{item.veiculoUFs.length == 1 ? 'uf selecionado' : 'ufs selecionados'}}</td>
                                        <td *ngIf="findValue(7)" class="">{{item.chassiRemarcado?'SIM':'NÃO'}}</td>
                                        <td *ngIf="findValue(8)" class=""> {{item.leilao?'SIM':'NÃO'}}</td>
                                        <td *ngIf="findValue(4)" class="">{{item.veiculoTiposCombustivel.length}} {{item.veiculoTiposCombustivel.length == 1 ? 'tipo de combustivel selecionado' : 'tipos de combustivel selecionados'}}</td>
                                        <td *ngIf="findValue(2)" class="">{{item.veiculoTipos.length}} tipo Veiculo</td>
                                        <td class="">{{item.percentualTabelaReferencia}}</td>
                                        <td class="text-right">
                                            <div dropdown container="table">
                                                <a class="dropdown-ellipses dropdown-toggle" dropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fe fe-more-vertical"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                                                    <a (click)="EditarItem(item)" class="dropdown-item cursor-pointer">Editar</a>
                                                    <a (click)="ExcluirItem(item)" class="dropdown-item cursor-pointer">Excluir</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>