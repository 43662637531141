import { Component, OnInit } from '@angular/core';
import {
  ApiClient,
  FiltroContratoStatus,
  ListarContratosResponseItem
  } from 'src/app/core/http/api-client.generated';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-contratos-listar',
  templateUrl: './contratos-listar.component.html',
  styleUrls: ['./contratos-listar.component.css']
})
export class ContratosListarComponent implements OnInit {
  contratos: ListarContratosResponseItem[];

  constructor(private apiClient: ApiClient,
              private toastr: ToastrService,
              private authService: AuthService) {
   }

  ngOnInit(): void {
    this.apiClient.contratos4(this.authService.estabelecimentoId, undefined, undefined, FiltroContratoStatus._0).subscribe((resp) =>{
      this.contratos = resp.contratos;
    })
  }

  getContratos(filtro: string){
    this.apiClient.contratos4(this.authService.estabelecimentoId, undefined, filtro).subscribe((resp) =>{
      this.contratos = resp.contratos;
    })
  }

}
