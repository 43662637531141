import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import {
  ApiClient,
  ListarContratoResponse,
  ListarGarantiasContratoResponseItem,
  ListarHistoricoMovimentacaoResponseItem
  } from 'src/app/core/http/api-client.generated';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-relatorio-situacao-detalhe',
  templateUrl: './relatorio-situacao-detalhe.component.html',
  styleUrls: ['./relatorio-situacao-detalhe.component.css']
})
export class RelatorioSituacaoDetalheComponent implements OnInit {

  id: string;
  contrato: ListarContratoResponse = new ListarContratoResponse();
  garantia: ListarGarantiasContratoResponseItem[];
  historico: ListarHistoricoMovimentacaoResponseItem[];
  currencyOption = environment.currencyOption;
  dataTermino = new Date();
  constructor(
    private apiClient: ApiClient,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
    });

    this.getContrato();
    this.getGarantia();
    this.getHistorico();
  }

  getContrato() {
    // tslint:disable-next-line: radix
    this.apiClient.contratos(parseInt(this.id)).subscribe( (resp) => {
      this.contrato = resp;
      this.dataTermino = this.contrato.dataTermino.toDate(); // = resp.dataTermino.toDate();
    });
  }

  getGarantia() {
    // tslint:disable-next-line: radix
    this.apiClient.garantias(parseInt(this.id)).subscribe( (resp) => {
      this.garantia = resp.garantias;
    });
  }

  getHistorico() {
    // tslint:disable-next-line: radix
    this.apiClient.historicoMovimentacao(parseInt(this.id)).subscribe( (resp) => {
      this.historico = resp.historico;
    });
  }

  get totalEmGarantia(): number {
    return this.garantia?.map(v => v.precoInstituicaoFinanceira || v.precoVeiculo).reduce((valor, soma) => valor + soma, 0);
  }
}
