<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Situação Atual dos Contratos
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col">
                                    <form [formGroup]="formulario">
                                        <div class="input-group input-group-flush">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"><i class="fe fe-search"></i></span>
                                            </div>
                                            <select formControlName="instituicaoFinanceiraId" class="form-control" *ngIf="authService.estabelecimentoId == 0">
                                                <option [value]="0" disabled>Selecione a Instituição Financeira</option>
                                                <option *ngFor="let instituicao of instituicoesFinanceiras" [value]="instituicao.id">{{ instituicao.razaoSocial }}</option>
                                            </select>
                                            <select formControlName="status" class="form-control">
                                                <option [value]="null" disabled>Selecione o status</option>                                                
                                                <option value="">Todos</option>
                                                <option [value]="0">Em andamento</option>
                                                <option [value]="1">Liquidados</option>
                                                <option [value]="2">Cancelados</option>
                                            </select>
                                            <input class="form-control" placeholder="Buscar por loja ou nº do contrato" formControlName="filtro">
                                            <button class="btn btn-outline-info" (click)="buscar()">
                                                <span class="fe fe-search"></span>
                                                Buscar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>N°</th>
                                        <th>N° Geero</th>
                                        <th>Tipo</th>
                                        <th>Loja</th>
                                        <th class="text-right">Crédito</th>
                                        <th class="text-right">Garantia</th>
                                        <th>Situação</th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr *ngFor="let item of contratos" (click)="getDetalhe(item.id)" style="cursor: pointer;">
                                        <td>{{ item.numero }}</td>
                                        <td>{{ item.idGeero }}</td>
                                        <td>{{ item.tipo }}</td>
                                        <td>{{ item.loja }}</td>
                                        <td class="text-right">{{ item.valorCredito | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                        <td class="text-right">{{ item.valorGarantia | currency:' ':'symbol-narrow':'1.2-2':'pt-BR' }}</td>
                                        <td>{{ item.status }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
