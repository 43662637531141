import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpUserEvent,
  HttpEvent,
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { user } from 'src/app/shared/models/user.model';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  count = 0;
  private currentUserSubject: BehaviorSubject<user>;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService
    ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

      this.count++;

      if (req.headers.get('No-Auth') === 'True') {
        return next.handle(req.clone()).pipe(finalize(() => {/*spinner on*/}));
      }

      if (this.authService.token != null) {
        const clonedreq = req.clone({
          headers: req.headers.set(
            'Authorization',
            'Bearer ' + this.authService.token
          ),
        });
        return next.handle(clonedreq).pipe(
          tap(
            (succ) => {

            },
            (err) => {
              if (err.status === 0) {
                this.toastr.error('Ocorreu um erro inesperado. Verifique sua conexão e tente novamente.');
                console.log("Ocorreu um erro inesperado. Verifique sua conexão e tente novamente.");
              } else if (err.status === 400) {
                if (err.error instanceof Blob) {
                  const reader = new FileReader();
                  reader.onload = (e: Event) => {
                    try {
                      //const errmsg = (<any>e.target).result.split('\n');
                      const errmsg = (<any>e.target).result.replace('Validation failed:', '').replace('-- :', '').replace('-- ', '');
                      this.toastr.error(errmsg);
                    } catch (e) {
                      this.toastr.error('Ocorreu um erro inesperado')
                    }
                  };
                  reader.onerror = (e) => {
                    console.log('Ocorreu um erro inesperado');
                    this.toastr.error('Ocorreu um erro inesperado')
                  };
                  reader.readAsText(err.error);
                }
              } else if (err.status === 401) {
                this.toastr.error('Sessão expirada');
                this.authService.logout();
              } else if (err.status === 403) {
                //console.log('authinterceptor-403');
                this.toastr.error('Ocorreu um erro inesperado. Verifique sua conexão e tente novamente.')
              } else if (err.status == 500) {
                if (err.error instanceof Blob) {
                  const reader = new FileReader();
                  reader.onload = (e: Event) => {
                    try {
                      const errmsg = JSON.parse((<any>e.target).result);

                      if (errmsg.erros) {
                        var erros: any[] = errmsg.erros;
                        var message = erros.map((_) => _.message).join('<br>');

                        console.log('authinterceptor-OK');
                        this.toastr.error('Ocorreu um erro inesperado. Verifique sua conexão e tente novamente.')
                      } else {
                        console.log('Ocorreu um erro inesperado');
                        this.toastr.error('Ocorreu um erro inesperado')
                      }

                    } catch (e) {
                      console.log('Ocorreu um erro inesperado');
                      this.toastr.error('Ocorreu um erro inesperado')
                    }
                  };
                  reader.onerror = (e) => {
                    console.log('Ocorreu um erro inesperado');
                    this.toastr.error('Ocorreu um erro inesperado')
                  };
                  reader.readAsText(err.error);
                } else {
                  console.log('Ocorreu um erro inesperado');
                  this.toastr.error('Ocorreu um erro inesperado')
                }
              }
            }
          ),
          finalize(() => {
            this.count--;
            if(this.count == 0) {/*spinner off*/};
          })
        );
      } else {
        this.router.navigateByUrl('/login');

        return next.handle(req).pipe(
          tap(
            (succ) => {},
            (err) => {
              if (err.status === 0) {
                this.toastr.error('Ocorreu um erro inesperado. Verifique sua conexão e tente novamente.');
              } else if (err.status === 401) {
                this.toastr.error('Credenciais inválidas.');
              } else {
                this.toastr.error('Ocorreu um erro inesperado.');
              }}),
          finalize(() => {
          this.count--;
          /*if(this.count == 0) this.spinner.hide();*/
        }));
      }
  }
}
