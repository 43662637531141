import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import {
  ApiClient,
  IListarLojaResponseItem,
  IListarInstituicaoFinanceiraResponseItem,
  ListarContratosResponseItem
} from 'src/app/core/http/api-client.generated';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-parametros-criar',
  templateUrl: './parametros-criar.component.html',
  styleUrls: ['./parametros-criar.component.css']
})
export class ParametrosCriarComponent implements OnInit {

  formGroup!: FormGroup;
  tipo: string;
  data: any;
  currencyOption = environment.currencyOption;
  lojas!: IListarLojaResponseItem[];
  instituicao!: IListarInstituicaoFinanceiraResponseItem[];
  InstituicaoFinanceiraId: string;
  contrato: ListarContratosResponseItem[];
  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private apiClient: ApiClient,
    private route: ActivatedRoute,
    private toastr: ToastrService) {
    }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      lojaId: [null],
      contratoId: [null],
      contratoNovo: [0.00],
      contratoAlterado: [0.00],
      garantiaIncluida: [0.00],
      garantiaRemovida: [0.00],
      regraGarantiaAlterada: [0.00]
    });

    this.InstituicaoFinanceiraId = this.data.instituicaoFinanceiraId;

    if (this.tipo === 'editar') {
      this.formGroup.controls['lojaId'].setValue(this.data.lojaId);
      this.formGroup.controls['contratoId'].setValue(this.data.contratoId);
      this.formGroup.controls['contratoNovo'].setValue(this.data.contratoNovo);
      this.formGroup.controls['contratoAlterado'].setValue(this.data.contratoAlterado);
      this.formGroup.controls['garantiaIncluida'].setValue(this.data.garantiaIncluida);
      this.formGroup.controls['garantiaRemovida'].setValue(this.data.garantiaRemovida);
      this.formGroup.controls['regraGarantiaAlterada'].setValue(this.data.regraGarantiaAlterada);

      if (this.data.contratoId > 0) {
        this.getContrato();
      }
    }

    this.getLojas(this.InstituicaoFinanceiraId);
  }

  Agregar() {

    // tslint:disable-next-line: radix
    this.formGroup.value.lojaId = parseInt(this.formGroup.value.lojaId);
    this.formGroup.value.contratoId = parseInt(this.formGroup.value.contratoId);
    this.event.emit(this.formGroup.value);
    this.bsModalRef.hide();
  }

  getLojas(id) {
    // tslint:disable-next-line: radix
    this.apiClient.whitelist(parseInt(id)).subscribe( (resp) => {
      this.lojas = resp.lojas;
    });
  }

  getContrato() {
    if (this.formGroup.value.lojaId !== '') {
      this.apiClient.contratos4(Number(this.InstituicaoFinanceiraId), parseInt(this.formGroup.value.lojaId)).subscribe( (resp) => {
        this.contrato = resp.contratos;
      });
    }
  }

}
