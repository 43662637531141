<div class="modal-card card">
    <div class="card-header">
        <h4 class="card-header-title" id="exampleModalCenterTitle">
            {{ tipo == 'criar' ? 'Cadastrar whitelist' : 'Alterar whitelist'}}
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
    </div>
</div>

<div class="modal-body">
    <form id="form_vehicle_out" [formGroup]="formGroup">
        <div class="form-row">
            <div class="form-group col-md-12">
                <label>Loja</label>
                <select class="custom-select" formControlName="lojaId">
                  <option disabled selected value="">Selecione</option>
                  <option *ngFor="let item of lojas" [value]="item.id">{{item.razaoSocial}}</option>
                </select>
                <div *ngIf="submitted && f.lojaId.errors" class="text-danger">
                    <div *ngIf="f.lojaId.errors">Campo obrigatório</div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12">
                <label>Instituição Financeira</label>
                <select class="custom-select" formControlName="instituicaoFinanceiraId">
                  <option disabled selected value="">Selecione</option>
                  <option *ngFor="let item of institucaoFinanceira" [value]="item.id">{{item.razaoSocial}}</option>
                </select>
                <div *ngIf="submitted && f.instituicaoFinanceiraId.errors" class="text-danger">
                    <div *ngIf="f.instituicaoFinanceiraId.errors">Campo obrigatório</div>
                </div>
            </div>
        </div>

        <div class="form-row">
            <div class="form-group col-md-12">
                <label>Prazo</label>
                <div class="input-group">
                    <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker [bsValue]="bsValue" [bsConfig]="{ adaptivePosition: true, containerClass: 'theme-default',dateInputFormat: 'DD/MM/YYYY' }" formControlName='prazo'>
                    <button class="btn btn-link" type="button" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button">
                      <i class="fe fe-calendar"></i>
                    </button>
                </div>
                <div *ngIf="submitted && f.prazo.errors" class="text-danger">
                    <div *ngIf="f.prazo.errors">Campo obrigatório</div>
                </div>
            </div>
        </div>
    </form>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white" data-dismiss="modal" (click)="bsModalRef.hide()">Cancelar</button>
    <button type="submit" form="form_vehicle_out" class="btn btn-primary" (click)="Agregar()">Salvar</button>
</div>
