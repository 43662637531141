<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
    <div class="container-fluid">

        <!-- Toggler -->
        <button class="navbar-toggler" type="button" (click)="isCollapsedGeral = !isCollapsedGeral" [attr.aria-expanded]="!isCollapsedGeral" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

        <a class="navbar-brand" routerLink='/home'>
            <img src="./assets/img/logo.png" class="navbar-brand-img mx-auto" alt="ControlStock">
        </a>

        <div class="navbar-user d-md-none">
            <div class="dropdown" dropdown>
                <a dropdownToggle class="navbar-user-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="icon "><i class="fe fe-user"></i></span>
                </a>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-card">
                    <div class="card-body">
                        <div class="list-group list-group-flush my-n3">
                            <a class="list-group-item text-reset">
                                <div class="row">
                                    <div class="col-auto cursor-pointer" (click)="logout()">
                                        <div class="small">
                                            <strong>SAIR</strong>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Collapse -->
        <div class="navbar-collapse" [collapse]="isCollapsedGeral" [isAnimated]="true" id="sidebarCollapse">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive='active' (click)="isCollapsedGeral = !isCollapsedGeral" routerLink='/home' aria-expanded="false" aria-controls="sidebarDashboards">
                        <i class="fe fe-home"></i> Início
                    </a>
                </li>

                <li class="nav-item" *permissao="'Configuracoes'">
                    <a class="nav-link" (click)="isCollapsedConfiguracoes = !isCollapsedConfiguracoes" [attr.aria-expanded]="!isCollapsedConfiguracoes" data-toggle="collapse" role="button" aria-expanded="false">
                        <i class="fe fe-settings"></i> Configurações
                    </a>
                    <div class="collapse" [collapse]="isCollapsedConfiguracoes" [isAnimated]="true" id="sidebarConfig">
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item" *permissao="'Configuracoes.ParametrosComerciais.Listar'">
                                <a routerLink='/configuracoes/parametros-comerciais' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link ">Parâmetros Comerciais</a>
                            </li>
                            <li class="nav-item" *permissao="'Configuracoes.Whitelist.Listar'">
                                <a routerLink='/configuracoes/whitelist' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link ">Whitelist</a>
                            </li>
                            <li class="nav-item" *permissao="'Configuracoes.RegrasGarantia.Listar'">
                                <a routerLink='/configuracoes/regras-garantia' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link ">Regras de Garantia</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item" *permissao="'Contratos'">
                    <a class="nav-link" (click)="isCollapsedUsuario = !isCollapsedUsuario" [attr.aria-expanded]="!isCollapsedUsuario" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarUser">
                        <i class="fe fe-layers"></i> Contratos
                    </a>
                    <div class="collapse" [collapse]="isCollapsedUsuario" [isAnimated]="true" id="sidebarAuth" *permissao="'Contratos.Contratos'">
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item" *permissao="'Contratos.Contratos.Listar'">
                                <a routerLink='/contratos/listar' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link">Todos os contratos</a>
                            </li>
                            <li class="nav-item" *permissao="'Contratos.Contratos.Criar'">
                                <a routerLink='/contratos/criar' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link ">Novo contrato</a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="nav-item" *permissao="'Relatorios'">
                    <a class="nav-link" (click)="isCollapsedManual = !isCollapsedManual" [attr.aria-expanded]="!isCollapsedManual" data-toggle="collapse" role="button" aria-expanded="false">
                        <i class="fe fe-file-text"></i> Relatórios
                    </a>
                    <div class="collapse" [collapse]="isCollapsedManual" [isAnimated]="true" id="sidebarConfig">
                        <ul class="nav nav-sm flex-column">
                            <li class="nav-item" *permissao="'Relatorios.HistoricoPrecificacao.Listar'">
                                <a routerLink='/relatorios/historico-precificacao' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link">Histórico de Precificação</a>
                            </li>
                            <li class="nav-item" *permissao="'Relatorios.SituacaoContratos.Listar'">
                                <a routerLink='/relatorios/situacao-contratos' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link ">Situação dos Contratos</a>
                            </li>
                            <li class="nav-item" *permissao="'Relatorios.ExtratoFinanceiro.Listar'">
                                <a routerLink='/relatorios/extrato-financeiro' (click)="isCollapsedGeral = !isCollapsedGeral" class="nav-link ">Extrato Financeiro</a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item d-md-none">
                    <a class="nav-link" routerLink='/notificacao' (click)="isCollapsedGeral = !isCollapsedGeral" data-toggle="modal">
                        <span class="fe fe-bell"></span> Notificações
                    </a>
                </li>
            </ul>
        </div>
        <!-- / .navbar-collapse -->
    </div>
</nav>