import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { user } from '../../shared/models/user.model';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  private currentUserSubject: BehaviorSubject<user>;
  public currentUser: Observable<user>;
  private _permissoes: string[] = [];

  constructor(private router: Router) {
    this.currentUserSubject = new BehaviorSubject<user>(this.parseToken());
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): user {
    return this.currentUserSubject.value;
  }

  logout() {
    sessionStorage.removeItem('GeeroStock.Token');
    sessionStorage.removeItem('GeeroStock.EstabelecimentoId');
    sessionStorage.removeItem('GeeroStock.Permissoes');
    this.currentUserSubject.next(null);
    this.router.navigateByUrl('/login');
  }

  public set token(token: string) {
    sessionStorage.setItem('GeeroStock.Token', token);
    this.currentUserSubject.next({token});
  }

  public get token(): string | null {
    return sessionStorage.getItem('GeeroStock.Token');
  }

  public get estabelecimentoId(): number {    
    return Number(sessionStorage.getItem('GeeroStock.EstabelecimentoId'));
  }

  public set estabelecimentoId(id: number) {
    sessionStorage.setItem('GeeroStock.EstabelecimentoId', id.toString());
    this.currentUserSubject.next({idInstitucao: id});
  }

  public get permissoes(): string[] {
    return JSON.parse(sessionStorage.getItem('GeeroStock.Permissoes'));
  }

  public set permissoes(permissoes: string[]) {
    sessionStorage.setItem('GeeroStock.Permissoes', JSON.stringify(permissoes));
  }

  parseToken() {
    var token = this.token;
    if(token == null) return null;

    return this.parseJwt(token);
  }

  parseJwt(token: string) {
    try {
      return JSON.parse(this.decodeUnicode(token.split('.')[0]));
    } catch (e) {
      return null;
    }
  }

  decodeUnicode(str: string) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  }
}
