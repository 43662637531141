import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  ApiClient,
  ListarInstituicaoFinanceiraResponseItem,
  ListarVeiculosEmGarantiaResponseItem
} from 'src/app/core/http/api-client.generated';
import { RelatorioHistoricoDetalheComponent } from '../relatorio-historico-detalhe/relatorio-historico-detalhe.component';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-relatorio-historico',
  templateUrl: './relatorio-historico.component.html',
  styleUrls: ['./relatorio-historico.component.css']
})
export class RelatorioHistoricoComponent implements OnInit {
  formulario: FormGroup;
  precificacoes: ListarVeiculosEmGarantiaResponseItem[];
  bsModalRef: BsModalRef;
  instituicoesFinanceiras: ListarInstituicaoFinanceiraResponseItem[] = [];
  pagina: number = 0;
  totalPaginas: number = 0;
  total: number = 0;
  first: number = 0;
  rows: number = 10;
  campoOrdenacao: string = "";
  ordenacao: string = "";

  constructor(private apiClient: ApiClient,
              private modalService: BsModalService,
              public authService: AuthService,
              private tostr: ToastrService) {
      this.formulario = new FormGroup({
        instituicaoFinanceiraId: new FormControl(0, Validators.required),
        veiculo: new FormControl(null)
      });
    }

  ngOnInit(): void {
    if (this.authService.estabelecimentoId > 0) {
      this.formulario.get('instituicaoFinanceiraId').setValue(this.authService.estabelecimentoId)
      this.buscar();
    } else {
      this.apiClient.instituicoesFinanceiras().subscribe(response => this.instituicoesFinanceiras = response.instituicoesFinanceiras);
    }
  }

  resetBusca(){
    this.buscar(true); 
  }
  
  sort(campo: string, ordenacao: string) {
    this.campoOrdenacao = campo;
    this.ordenacao = ordenacao;
    this.buscar();
  }

  abrirDetalhe(veiculoId) {
    this.bsModalRef = this.modalService.show(RelatorioHistoricoDetalheComponent, { initialState: {
      id : veiculoId
    }});
  }

  paginate(event) {
    this.pagina = event.page;
    this.buscar();
  }

  buscar(reset: boolean = false) {
    let ordenacao = this.campoOrdenacao + ":" + this.ordenacao;
    const filtro = this.formulario.get('veiculo').value;

    if (this.formulario.get('instituicaoFinanceiraId').value == 0){
      this.tostr.error('Selecione a Instituição Financeira');
      return;
    }

    if (filtro?.trim().length > 0) {
      this.apiClient.garantias3(this.formulario.get('instituicaoFinanceiraId').value, filtro, this.pagina + 1, this.rows, ordenacao).subscribe( (resp) => {
        if (reset) {
          this.pagina = 0;
        }
        this.precificacoes = resp.veiculos;
        this.totalPaginas = resp.totalPaginas;
        this.total = resp.total;
        this.first = resp.registroAtual;
        if (resp.veiculos?.length == 0) {
          this.tostr.info('A consulta não retornou nenhum registro.');
        }
      });
    } else {
      this.apiClient.garantias3(this.formulario.get('instituicaoFinanceiraId').value, undefined, this.pagina + 1, this.rows, ordenacao).subscribe( (resp) => {
        if (reset) {
          this.pagina = 0;
        }
        this.precificacoes = resp.veiculos;
        this.totalPaginas = resp.totalPaginas;
        this.total = resp.total;
        this.first = resp.registroAtual;
        if (resp.veiculos?.length == 0) {
          this.tostr.info('A consulta não retornou nenhum registro.');
        }
      });
    }
  }
}
