<div class="header">
    <div class="container-fluid">
        <div class="header-body">
            <div class="row align-items-end">
                <div class="col">
                    <h6 class="header-pretitle">
                        GeeroStock
                    </h6>
                    <h1 class="header-title">
                        Parâmetros Comerciais
                    </h1>
                </div>
                <div class="col-auto">
                    <a (click)="AdicionarParametro()" data-toggle="modal" class="btn btn-primary lift">
                        <span class="fe fe-plus"></span>
                        <span class="d-none d-md-inline">Novo Parâmetro</span>
                    </a>
                    &nbsp;
                    <a routerLink='/configuracoes/parametros-comerciais' class="btn btn-dark lift">
                        <span class="fe fe-arrow-left"></span>
                        <span class="d-none d-md-inline">Voltar</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="tab-content">
    <div class="tab-pane fade show active" id="tab-usuarios" role="tabpanel">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="card" id="dealsList">
                        <div class="table-responsive">
                            <table class="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th>Aplicação</th>
                                        <th>Loja</th>
                                        <th>Contrato</th>
                                        <th class="text-right" title="Contrato Incluído">Contrato Inc.</th>
                                        <th class="text-right" title="Contrato Alterado">Contrato Alt.</th>
                                        <th class="text-right" title="Garantia Incluída no Contrato">Garantia Inc.</th>
                                        <th class="text-right" title="Garantia Removida do Contrato">Garantia Rem.</th>
                                        <th class="text-right" title="Regra de Garantia Alterada">Regra Alt.</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody class="list">
                                    <tr *ngFor="let item of comerciais; let i = index" class="cursor-pointer">
                                        <td class="">{{ item.contratoId != null ? 'Contrato' : (item.lojaId != null ? 'Loja' : 'Geral') }}</td>
                                        <td class="">{{ item.loja }}</td>
                                        <td class="">{{ item.contrato }}</td>
                                        <td class="text-right">{{item.contratoNovo | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td class="text-right">{{item.contratoAlterado | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td class="text-right">{{item.garantiaIncluida | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td class="text-right">{{item.garantiaRemovida | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td class="text-right">{{item.regraGarantiaAlterada | currency:' ':'symbol-narrow':'1.2-2':'pt-BR'}}</td>
                                        <td class="text-right">
                                            <div dropdown container="table">
                                                <a class="dropdown-ellipses dropdown-toggle" dropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fe fe-more-vertical"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                                                    <a (click)="EditarParametro(item)" class="dropdown-item">Editar</a>
                                                    <a class="dropdown-item" (click)="ExcluirParametro(item.id)">Excluir</a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="card-footer d-flex justify-content-between"> -->

                        <!-- Paginacao (<) -->
                        <!-- <ul class="list-pagination-prev pagination pagination-tabs card-pagination">
                              <li class="page-item">
                                  <a class="page-link pl-0 pr-4 border-right" href="#">
                                      <i class="fe fe-arrow-left"></i>
                                  </a>
                              </li>
                          </ul>
-->
                        <!-- Paginacao -->
                        <!--                             <ul class="list-pagination pagination pagination-tabs card-pagination"></ul>
-->
                        <!-- Paginacao (>) -->
                        <!--   <ul class="list-pagination-next pagination pagination-tabs card-pagination">
                              <li class="page-item">
                                  <a class="page-link pl-4 pr-0 border-left" href="#">
                                      <i class="fe fe-arrow-right"></i>
                                  </a>
                              </li>
                          </ul> -->

                        <!--                         </div>
-->
                    </div>

                </div>
            </div>
            <!-- / .row -->
        </div>
    </div>
</div>
