import { Component, OnInit, SimpleChange } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import {
  ApiClient,
  IListarLojaResponseItem,
  IncluirGarantiasContratoVeiculoRequest,
  ListarEstoqueResponseItem,
  ListarRegraGarantiaResponseItem
  } from 'src/app/core/http/api-client.generated';
import { AuthService } from 'src/app/core/auth/auth.service';
import { EstoqueGarantiaItem } from 'src/app/model/EstoqueGarantiaItem';

@Component({
  selector: 'app-contratos-criar',
  templateUrl: './contratos-criar.component.html',
  styleUrls: ['./contratos-criar.component.css']
})

export class ContratosCriarComponent implements OnInit {

  garantias: EstoqueGarantiaItem[];
  formGroup!: FormGroup;
  lojas!: IListarLojaResponseItem[];
  tipo = 'criar';
  propostaCancelar = false;
  enviarLoja = false;
  recusarProposta = false;
  currencyOption = environment.currencyOption;
  submitted = false;
  bsModalRef: BsModalRef;
  minDate: Date;
  idContrato: string;
  veiculosGarantia: IncluirGarantiasContratoVeiculoRequest[] = [];
  qtdGarantia = 0;
  showAlert = false;
  regras: ListarRegraGarantiaResponseItem[] = [];

  constructor(private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private apiClient: ApiClient,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private authService: AuthService) {
    }

  get f() { return this.formGroup.controls; }

  ngOnInit(): void {
    this.minDate = new Date();
    this.formGroup = this.formBuilder.group({
      lojaId: [null, Validators.compose([Validators.required])],
      regraGarantiaId: [null],
      numero: [null, Validators.compose([Validators.required])],
      valorCredito: [0, Validators.compose([Validators.required, Validators.min(0.01)])],
      valorGarantia: [0, Validators.compose([Validators.required, Validators.min(0.01)])],
      dataTermino: [null, Validators.compose([Validators.required])],
      tipo: ['1', Validators.compose([Validators.required])],
      tipoSelecaoGarantia: ['1', Validators.compose([Validators.required])],
    });

    this.getLojas(this.authService.estabelecimentoId);
  }

  salvarRascunho() {
    this.submitted = true;

    if (!this.formGroup.valid) {
      return;
    }

    if (this.garantias.filter(g => g.emGarantia == true && g.precoInstituicaoFinanceira <= 0).length > 0) {
      return;
    }

    this.formGroup.value.instituicaoFinanceiraId = this.authService.estabelecimentoId;
    this.formGroup.value.tipo = Number(this.formGroup.value.tipo);
    this.formGroup.value.lojaId = Number(this.formGroup.value.lojaId);
    this.formGroup.value.tipoSelecaoGarantia = Number(this.formGroup.value.tipoSelecaoGarantia);
    this.formGroup.value.regraGarantiaId = Number(this.formGroup.value.regraGarantiaId);
    this.formGroup.value.dataTermino = moment(this.formGroup.value.dataTermino).format('YYYY-MM-DDThh:mm:ss[Z]');
    this.apiClient.contratos3(this.formGroup.value).subscribe(response => {
      if (this.formGroup.value.tipoSelecaoGarantia == 1) {
        if (this.veiculosGarantia.length > 0) {
          this.AdicionarGarantias(response.id);
        }
      } else if (this.formGroup.value.tipoSelecaoGarantia == 2) {
        console.log(response);
        if (response.garantias.length == 0) {
          this.toastr.error('Não foi possível selecionar as garantias automaticamente.');
        }
      }
      this.toastr.success('Proposta gerada com sucesso.');
      this.router.navigateByUrl('/contratos/detalhe/' + response.id);
    });
  }

  get totalEmGarantia(): number {
    return this.veiculosGarantia?.map(v => v.precoInstituicaoFinanceira || v.precoVeiculo).reduce((valor, soma) => valor + soma, 0);
  }

  AdicionarGarantias(contratoId) {
    this.apiClient.garantias2(contratoId, undefined, this.veiculosGarantia).subscribe((resp) => {
      this.tipo = 'rascunho';
//      this.router.navigateByUrl('/contratos/criar/' + contratoId);
    });
  }

  SelecionarGarantias() {
    this.qtdGarantia = 0;
    this.veiculosGarantia = [];

    this.garantias.find(x => {
      if (x.emGarantia) {
        var garantia = new IncluirGarantiasContratoVeiculoRequest();

        garantia.veiculoId = x.id;
        garantia.precoVeiculo = x.preco;
        garantia.precoInstituicaoFinanceira = x.precoInstituicaoFinanceira;

        this.veiculosGarantia.push(garantia);
      }
    });

    this.qtdGarantia = this.veiculosGarantia.length;
  }

  CancelSelect() {
    this.veiculosGarantia = [];
    this.garantias.find(x => {
      if (x.emGarantia) {
        x.emGarantia = false;
      }
    });
  }

  getLojas(id) {
    // tslint:disable-next-line: radix
    this.apiClient.whitelist(parseInt(id)).subscribe( (resp) => {
      this.lojas = resp.lojas;
    });
  }

  getGarantias(id) {    
    if (this.regras.length == 0) {
      this.apiClient.regrasGarantia3(this.authService.estabelecimentoId).subscribe(response => {
        this.regras = response.regrasGarantia.filter(r => r.aplicacao == 'Geral' || (r.aplicacao == 'Loja' && r.lojaId == this.formGroup.controls.lojaId.value));
        this.formGroup.controls.regraGarantiaId.setValue(this.regras.filter(r => r.aplicacao == 'Geral')[0].id);
      });  
    }    

    this.apiClient.estoque(Number(id), undefined, undefined, Number(this.formGroup.controls.regraGarantiaId.value)).subscribe( (resp) => {
      this.garantias = resp.estoque.map(e => new EstoqueGarantiaItem(e, e.preco));
    });
  }



  getSelecaoGarantia(IdGarantia) {
    let garantia;
    switch (IdGarantia) {
      case 'Loja':
        garantia = '1';
        break;
      case 'InstituicaoFinanceira':
        garantia = '2';
        break;
      case 'Automatico':
        garantia = '3';
        break;
      default:
        garantia = '';
        break;
    }
    return garantia;
  }

}
