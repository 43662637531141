<div class="modal-card card">
    <div class="card-header">
        <h4 class="card-header-title" id="exampleModalCenterTitle">
            {{tipo == 'Novo' ? 'Novo' : 'Editar'}} item
        </h4>
        <button type="button" (click)="bsModalRef.hide()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
</button>
    </div>
</div>
<div class="modal-body">
    <form id="form_vehicle_out" [formGroup]="formGroup">
        <div class="form-row">
            <div class="form-group col-md-6" *ngIf="findValue(0)">
                <label>Marca</label>
                <ng-select [items]="marcas?.listaVeiculoMarca" [multiple]="true" bindLabel="nome" [selectableGroup]="true" (change)="getModelo(formGroup.value.veiculoMarcaIds)" [closeOnSelect]="false" bindValue="id" formControlName="veiculoMarcaIds">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" /> {{item.nome}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group col-md-6" *ngIf="findValue(1)">
                <label>Modelo</label>
                <select class="custom-select" multiple formControlName="veiculoModeloIds">
                <option disabled value="">Selecione</option>
                <option *ngFor="let item of modelos" [value]="item.id">{{item.nome}}</option>
              </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6" *ngIf="findValue(5)">
                <label>Idade máxima</label>
                <input type="number" class="form-control" formControlName='idadeMaxima'>
            </div>
            <div class="form-group col-md-6" *ngIf="findValue(6)">
                <label>Km máxima</label>
                <input type="text" class="form-control" formControlName="quilometragemMaxima" mask="separator" thousandSeparator=".">

            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6" *ngIf="findValue(2)">
                <label>Tipo de Veículo</label>
                <ng-select [items]="tipoVeiculo" [multiple]="true" bindLabel="nome" [selectableGroup]="true" [closeOnSelect]="false" bindValue="id" formControlName="veiculoTipoIds">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" /> {{item.nome}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="form-group col-md-6" *ngIf="findValue(4)">
                <label>Tipo de Combustível</label>
                <ng-select [items]="tipoCombustive" [multiple]="true" bindLabel="nome" [selectableGroup]="true" [closeOnSelect]="false" bindValue="id" formControlName="veiculoTipoCombustivelIds">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" /> {{item.nome}}
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6" *ngIf="findValue(3)">
                <label>Uf</label>
                <ng-select [items]="ufs" [multiple]="true" bindLabel="nome" [selectableGroup]="true" [closeOnSelect]="false" bindValue="id" formControlName="veiculoUFs">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" /> {{item.nome}}
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6" *ngIf="findValue(7)">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="chassi" formControlName="chassiRemarcado">
                    <label class="custom-control-label" for="chassi">Chassi remarcado</label>
                </div>
            </div>
            <div class="form-group col-md-6">
                <div class="custom-control custom-checkbox" *ngIf="findValue(8)">
                    <input type="checkbox" class="custom-control-input" id="veiculo" formControlName="leilao">
                    <label class="custom-control-label" for="veiculo">Veiculo de leilão</label>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <label>% Tabela de Referência</label>
                <input type="text" class="form-control" mask="percent" suffix="%" formControlName="percentualTabelaReferencia">
                <div *ngIf="submitted && f.percentualTabelaReferencia.errors" class="text-danger">
                    <div *ngIf="f.percentualTabelaReferencia.errors">Campo obrigatório.</div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-white" data-dismiss="modal" (click)="bsModalRef.hide()">Cancelar</button>
    <button type="submit" form="form_vehicle_out" class="btn btn-primary" (click)="Agregar()">Adicionar item</button>
</div>