import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';

import {
  AlterarItensRegraGarantiaRequestItem,
  ApiClient,
  IncluirItensRegraGarantiaRequest,
  IncluirItensRegraGarantiaRequestItem,
  ListarItensRegraGarantiaResponseItem
  } from 'src/app/core/http/api-client.generated';
import { ModalConfirmComponent } from 'src/app/shared/layout/modal-confirm/modal-confirm.component';
import { RegraItemsCriarComponent } from '../regra-items-criar/regra-items-criar.component';
@Component({
  selector: 'app-regra-items',
  templateUrl: './regra-items.component.html',
  styleUrls: ['./regra-items.component.css']
})
export class RegraItemsComponent implements OnInit {

  itens: ListarItensRegraGarantiaResponseItem[] = [];

  itensRequest: IncluirItensRegraGarantiaRequestItem[] = [];
  itensEditar: AlterarItensRegraGarantiaRequestItem[] = [];
  incluirItensRegraGarantiaRequest: IncluirItensRegraGarantiaRequest =
  new IncluirItensRegraGarantiaRequest();
  regraHead: any;

  bsModalRef: BsModalRef;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiClient: ApiClient,
    private modalService: BsModalService,
    private cookieService: CookieService,
    private toastr: ToastrService
    ) {
      this.regraHead = this.cookieService.getObject('regra');
    }

  ngOnInit(): void {
    this.AdicionarIten();
  }

  AdicionarIten() {
    this.bsModalRef = this.modalService.show(RegraItemsCriarComponent, {
      initialState: {
        tipo: 'Novo',
        filtrosRegra: this.regraHead
      }
    });

    this.bsModalRef.content.event.subscribe((res) => {
        let iten: IncluirItensRegraGarantiaRequestItem = new IncluirItensRegraGarantiaRequestItem();
        iten.veiculoMarcaIds = res.veiculoMarcaIds;
        iten.veiculoModeloIds = res.veiculoModeloIds;
        iten.veiculoTipoIds = res.veiculoTipoIds;
        iten.veiculoTipoCombustivelIds = res.veiculoTipoCombustivelIds;
        iten.idadeMaxima = res.idadeMaxima == null ? null : Number(res.idadeMaxima);
        iten.quilometragemMaxima = res.quilometragemMaxima == null ? null : Number(res.quilometragemMaxima);
        iten.veiculoUFs = res.veiculoUFs;
        iten.chassiRemarcado = res.chassiRemarcado;
        iten.leilao = res.leilao;
        iten.percentualTabelaReferencia = res.percentualTabelaReferencia == null ? null : Number(res.percentualTabelaReferencia);
        this.itensRequest.push(iten);

        let iten2: ListarItensRegraGarantiaResponseItem = new ListarItensRegraGarantiaResponseItem();
        iten2.veiculoMarcas = res.veiculoMarcaIds;
        iten2.veiculoModelos = res.veiculoModeloIds;
        iten2.veiculoTipos = res.veiculoTipoIds;
        iten2.veiculoTiposCombustivel = res.veiculoTipoCombustivelIds;
        iten2.idadeMaxima = res.idadeMaxima == null ? null : Number(res.idadeMaxima);
        iten2.quilometragemMaxima = res.quilometragemMaxima == null ? null : Number(res.quilometragemMaxima);
        iten2.veiculoUFs = res.veiculoUFs;
        iten2.chassiRemarcado = res.chassiRemarcado;
        iten2.leilao = res.leilao;
        iten2.percentualTabelaReferencia = res.percentualTabelaReferencia == null ? null : Number(res.percentualTabelaReferencia);

        this.itens.push(iten2);
    });
  }

  Adicionar() {
    if (this.regraHead.lojaId != null) {
      this.regraHead.lojaId = Number(this.regraHead.lojaId);
    }
    this.regraHead.contratoId = this.regraHead.contratoId == null ? null : Number(this.regraHead.contratoId);
    this.regraHead.tabelaReferencia = Number(this.regraHead.tabelaReferencia);

    this.apiClient.regrasGarantia4(this.regraHead).subscribe( (resp) => {
      this.incluirItensRegraGarantiaRequest.regraGarantiaId = resp.id;
      this.incluirItensRegraGarantiaRequest.itens = this.itensRequest;

      this.apiClient.itens2(Number(resp.id), this.incluirItensRegraGarantiaRequest).subscribe( (resp2) => {
        this.toastr.success('Adicionado com sucesso');
        this.cookieService.remove('regra');
        this.router.navigate(['/configuracoes/regras-garantia']);
      });
    });
  }

  Cancelar() {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, {
      initialState: {
        showCancel: true,
        titulo: 'Atenção',
        mensaje: 'Deseja cancelar a inclusão da regra?',
        textCancelar: 'NÃO',
        textConfirmar: 'SIM'
      }, class: 'modal-sm'
    });

    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        this.cookieService.remove('regra');
        this.router.navigate(['/configuracoes/regras-garantia']);
      }
    });
  }

  ExcluirItem(item)
  {
    console.log(this.itens.indexOf(item));
  }

  EditarItem(item) {
    this.bsModalRef = this.modalService.show(RegraItemsCriarComponent, {
      initialState: {
        tipo: 'Editar',
        data: item,
        filtrosRegra: this.regraHead
      }
    });

    this.bsModalRef.content.event.subscribe((res) => {
      item.veiculoMarcaIds = res.veiculoMarcaIds;
      item.veiculoModeloIds = res.veiculoModeloIds;
      item.veiculoTipoIds = res.veiculoTipoIds;
      item.veiculoTipoCombustivelIds = res.veiculoTipoCombustivelIds;
      item.idadeMaxima = res.idadeMaxima == null ? null : Number(res.idadeMaxima);
      item.quilometragemMaxima = res.quilometragemMaxima == null ? null : Number(res.quilometragemMaxima);
      item.veiculoUFs = res.veiculoUFs;
      item.chassiRemarcado = res.chassiRemarcado;
      item.leilao = res.leilao;
      item.percentualTabelaReferencia = res.percentualTabelaReferencia == null ? null : Number(res.percentualTabelaReferencia);
    });
  }

  findValue(value){
    var rtn = this.regraHead.filtros.find(item => item.filtro === value);

    return rtn;

  }

}
