import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { user } from '../../../shared/models/user.model';

import {
  ApiClient,
  ListarNotificacoesResponseItem
  } from 'src/app/core/http/api-client.generated';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  notificacoes: ListarNotificacoesResponseItem[];
  bsModalRef: BsModalRef;
  msjNoLidas = true;
  private currentUserSubject: BehaviorSubject<user>;

  constructor(private apiClient: ApiClient,
              private modalService: BsModalService,
              private router: Router,
              private authService: AuthService) {      
    }

  ngOnInit(): void {
    this.getnotificacao();

    setInterval(() => {
      this.getnotificacao();
    }, 60000);

  }

  getnotificacao() {
    this.apiClient.notificacoes(Number(this.authService.estabelecimentoId)).subscribe((resp) => {
      this.notificacoes = resp.notificacoes;
      this.notificacoes.splice(3);

      const msg = this.notificacoes.findIndex(x => x.lida === false);
      if (msg === -1) {
        this.msjNoLidas = false;
      } else {
        this.msjNoLidas = true;
      }
    });
  }

  detalheNotificacao(item) {
    this.bsModalRef = this.modalService.show(ModalConfirmComponent, { initialState: {
      titulo: item.titulo,
      mensaje: item.conteudo,
      textCancelar: 'NÃO',
      textConfirmar: 'OK',
      showCancel: false
    }, class: 'modal-sm'});


    this.bsModalRef.content.event.subscribe(res => {
      if (res) {
        this.apiClient.ler(item.id, Number(this.authService.estabelecimentoId)).subscribe((resp) => {
          this.getnotificacao();
        });
      }
    });
  }

  logout() {
    // remove user from local storage to log user out
    this.authService.logout();
  }
}
