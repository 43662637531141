<div class="modal-card card">
    <div class="card-header">
        <h4 class="card-header-title" id="exampleModalCenterTitle">{{titulo}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="modal-body">
    <p class="mb-0">{{mensaje}}</p>
    <form id="form_vehicle_out" [formGroup]="formGroup">
        <textarea class="form-control" name="motivo" id="motivo" cols="30" rows="5" formControlName="motivo"></textarea>
        <div *ngIf="submitted && f.motivo.errors" class="text-danger">
            <div *ngIf="f.motivo.errors">Campo obrigatório</div>
        </div>
    </form>
</div>
<div class="modal-footer ">
    <div class="text-center col">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal" (click)="confirmar()">Confirmar</button>&nbsp;
        <button type="button" class="btn btn-outline-dark" data-dismiss="modal" (click)="bsModalRef.hide()">Cancelar</button> </div>
</div>