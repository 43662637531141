import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxCurrencyModule } from 'ngx-currency';
import { RelatorioHistoricoComponent } from './relatorio-historico/relatorio-historico.component';
import { RelatorioSituacaoComponent } from './relatorio-situacao/relatorio-situacao.component';
import { RelatorioHistoricoDetalheComponent } from './relatorio-historico-detalhe/relatorio-historico-detalhe.component';
import { RelatorioRoutingModule } from './relatorio-routing.module';
import { RelatorioSituacaoDetalheComponent } from './relatorio-situacao-detalhe/relatorio-situacao-detalhe.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';


import { ToastrModule } from 'ngx-toastr';
import { ExtratoFinanceirosComponent } from './extrato-financeiros/extrato-financeiros.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    RelatorioHistoricoComponent,
    RelatorioSituacaoComponent,
    RelatorioSituacaoDetalheComponent,
    RelatorioHistoricoDetalheComponent,
    ExtratoFinanceirosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    RelatorioRoutingModule,
    TabsModule.forRoot(),
    NgxCurrencyModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginatorModule,
    ButtonModule
  ]
})

export class RelatorioModule{

}
