import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ParametrosComponent } from './parametros-comerciais/parametros/parametros.component';
import { WhitelistComponent } from './whitelists/whitelist/whitelist.component';
import { ParametrosDetailComponent } from './parametros-comerciais/parametros-detail/parametros-detail.component';

import { RegrasListarComponent } from './regras-garantia/regras-listar/regras-listar.component';
import { RegraItemsComponent } from './regras-garantia/regra-items/regra-items.component';
import { RegraDetalhesComponent } from './regras-garantia/regra-detalhes/regra-detalhes.component';
import { PermissaoGuard } from 'src/app/core/guards/permissao.guard';

const routes: Routes = [
  {
    path: 'parametros-comerciais',
    component: ParametrosComponent,
    data: { permissao: 'Configuracoes.ParametrosComerciais.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'parametros-comerciais/detalhe/:id',
    component: ParametrosDetailComponent,
    data: { permissao: 'Configuracoes.ParametrosComerciais.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'whitelist',
    component: WhitelistComponent,
    data: { permissao: 'Configuracoes.Whitelist.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'regras-garantia',
    component: RegrasListarComponent,
    data: { permissao: 'Configuracoes.RegrasGarantia.Listar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'regras-garantia/itens/criar',
    component: RegraItemsComponent,
    data: { permissao: 'Configuracoes.RegrasGarantia.Criar' },
    canActivate: [PermissaoGuard]
  },
  {
    path: 'regras-garantia/itens/detalhe/:id',
    component: RegraDetalhesComponent,
    canActivate: [PermissaoGuard]
  },
  {
    path: '**',
    redirectTo: 'parametros-comerciais',
    pathMatch: 'full',
    canActivate: [PermissaoGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfiguracoesRoutingModule { }
